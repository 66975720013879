import React, { FC } from "react";
import AppStore from "./AppStore";

const AppContext = React.createContext(new AppStore());

type AppProviderProps = {
    store: AppStore;
    children: React.ReactNode;
};

const AppProvider: FC<AppProviderProps> = (props: AppProviderProps) => {
    return (
        <AppContext.Provider value={props.store}>
            {" "}
            {props.children}{" "}
        </AppContext.Provider>
    );
};

const useAppStore = (): AppStore => React.useContext(AppContext);

export default AppProvider;
export { AppContext, useAppStore };

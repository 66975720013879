import React, { useState, useEffect, useRef } from "react";

interface FPSCounterProps {
    refreshRate: number; // Le taux de rafraîchissement du compteur de FPS en millisecondes
}

const FPSCounter: React.FC<FPSCounterProps> = ({ refreshRate }) => {
    const [fps, setFps] = useState(0);
    const count = useRef(0);
    useEffect(()=>{
        let startTime = Date.now();
        const intervalFps = setInterval(() => {
            count.current = count.current + 1;
        }, refreshRate);

        const interval = setInterval(() => {
            const currentTime = Date.now();
            const elapsed = currentTime - startTime;
            const currentFps = Math.round((count.current / elapsed) * 1000);
            setFps(currentFps);
            count.current = 0;
            startTime = currentTime;
        }, 1000);
        return () => {
            clearInterval(interval);
            clearInterval(intervalFps);
        };
    }, [refreshRate]);
    return <div>FPS: {fps}</div>;
};

export default FPSCounter;
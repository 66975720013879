import Case from "case";
import Offer from "../store/Offer";
import { RGBA } from "./color";
import { hexToRGBA } from "./color";

class StyleManipulator {

    static _instance_:StyleManipulator|undefined;
    
    public styleVariables:Map<string, string> = new Map<string, string>();

    static getInstance():StyleManipulator {
        if (!StyleManipulator._instance_) {
            StyleManipulator._instance_ = new StyleManipulator();
        }
        return StyleManipulator._instance_;
    }

    public getStyleVariable(name:string): string|undefined {
        // '--offer-unselected'
        if (!this.styleVariables.has(name)) {
            const style = getComputedStyle(document.documentElement).getPropertyValue(name);
            this.styleVariables.set(name, style);    
        }
        return this.styleVariables.get(name);        
    }    

    public getStyleVariableAsNum(name:string):number {
        let value = this.getStyleVariable(name);
        if (!value) {
            return 0;
        }
        value = value?.replace("px", "");
        return parseFloat(value);
    }


    public setStyleVariable(name:string, value:string):void {
        const r = document.querySelector<HTMLElement>(':root');
        if (r) {
            r.style.setProperty(name, value);
            //r["style"].setProperty(name, value);
        }
    }
}

export function getStringMetrics(str: string, fontSize: number, fontFamily: string): TextMetrics {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
        context.font = `${fontSize}px ${fontFamily}`;
        const metrics = context.measureText(str);    
        return metrics;
    }
    return new TextMetrics();
}

export const wordWrap = (str:string, max:number, br = '\n'):string => str.replace(
    new RegExp(`(?![^\\n]{1,${max}}$)([^\\n]{1,${max}})\\s`, 'g'), '$1' + br
);

class TextWrapper {

    static _instance_:TextWrapper|undefined;
    protected _textSizes:Map<string, number> = new Map();

    static getInstance():TextWrapper {
        if (!TextWrapper._instance_) {
            TextWrapper._instance_ = new TextWrapper();
        }
        return TextWrapper._instance_;
    }


    public getTextWidth(text:string, fontSize:number, fontFamily:string):number {
        const uuid = `${text}${fontSize}${fontFamily}`;
        if (!this._textSizes.has(uuid)) {
            const metrics = getStringMetrics(text, fontSize, fontFamily);
            this._textSizes.set(uuid, metrics.width);
        }
        return this._textSizes.get(uuid) ?? 0;
    }

}


const styleManipulator = StyleManipulator.getInstance();
const wrapper = TextWrapper.getInstance();


function getRingColor(offer:Offer, serviceUuid:string, selected:boolean = false):string|undefined {

    let cssVariable = "--offer-unselected";

    if (!selected) {
        cssVariable = offer.style.primary_color;
    }

    if (offer.services.full.includes(serviceUuid)) {
        cssVariable = offer.style.primary_color;
    }

    if (offer.services.medium.includes(serviceUuid)) {
        cssVariable = offer.style.secondary_color;
    }

    return styleManipulator.getStyleVariable(cssVariable);
}

function getOfferColor(offer:Offer):string {
    const color = styleManipulator.getStyleVariable(offer.style.primary_color);
    return color ?? "#ffffff";
}

function cssNameToReact(name:string):string {
    return Case.camel(name);
}

export function cssToRGBA(css:string):RGBA[] {
    const colors:RGBA[] = [];
    let regEx = /#\s*([0-9a-fA-F]{6})/gm;    
    const r = [...css.matchAll(regEx)];
    if (r.length) {
        return [hexToRGBA(r[0][1])];
    }

    regEx = /rgba\(\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([.0-9]*)\s*\)/gm;    
    const results = [...css.matchAll(regEx)];
    if (results.length) {
        results.forEach(r => {
            const color = {
                r: parseInt(r[1]),
                g: parseInt(r[2]),
                b: parseInt(r[3]),
                a: parseFloat(r[4]),
            };
            colors.push(color);
        });    
    }

    return colors;
}

export function cssToPixel(css:string):number[] {
    const regEx = /([0-9.]*)px/gm;
    const results = [...css.matchAll(regEx)];
    const corners:number[] = [];
    if (results.length > 0) {
        results.forEach(r => {
            corners.push(parseInt(r[1]));
        });
        return corners;
    }
    return [0];
}

export function rgbaToCSS(rgba:RGBA):string {
    return `rgba(${Math.round(rgba.r)}, ${Math.round(rgba.g)}, ${Math.round(rgba.b)}, ${rgba.a})`;
}

export { 
    styleManipulator, 
    wrapper, 
    getRingColor, 
    getOfferColor,
    cssNameToReact 
};


import React, { useEffect, useRef } from "react";
import { MovieClipProps } from ".";
import "./_movieclips.scss";
import { gsap } from "gsap";
import resolutionManager from "../../tools/ResolutionManager";



export enum ToggleState {
    selected = "selected",
    unselected = "unselected",
}

//const ToggleClip = React.forwardRef<AnimateElementHandle, MovieClipProps>(function ToggleClip({id, state, className, style, children, onClick}:MovieClipProps, ref:React.Ref<unknown>): React.ReactElement {

const ToggleClip = function ToggleClip({id, state, className, style, children, onClick}:MovieClipProps): React.ReactElement {
    
    const mainRef = useRef(null);


    useEffect(()=>{        
        gsap.context(() => {
            if (state === ToggleState.selected) {
                //const delay = gsap.utils.random(0, 1);
                gsap.to(".front-image", {
                    opacity: 1,
                    delay: 0
                });
            }
            else {
                gsap.to(".front-image", {
                    opacity: 0
                });
            }
        }, mainRef);

    }, [state]);

    const handleClick = (event:React.MouseEvent) => {
        // event.stopPropagation();
        if (onClick) {
            onClick(event);
        }
    };

    const scaledSytyle = resolutionManager.scaleStyle(style);
    return (
        <div id={id} ref={mainRef} className={className} style={scaledSytyle} onClick={handleClick}>
            { children }
            <div className="back-image"></div>
            <div className="front-image"></div>
        </div>
    );
};

export default ToggleClip;
import axios, { AxiosInstance } from "axios";
import { AppLogger } from "../../tools/AppLogger";
import Offer, { Criteria, CriteriaMeta, PopupContent, PopupDTO, RowMapping, RowMeta, Service, criteriaNameToUuid, getRange, notFoundService } from "../Offer";
import { SimpleNode } from "../../tools/tree";
import { Dictionary } from "../../tools/types";

export default class SimpleClient {

    protected _client: AxiosInstance;

    constructor() {
        this._client = axios.create({
            baseURL: window.location.origin,
            timeout: 10000,
        });
        this._client.defaults.headers.get = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        };
    }

    public async loadOffers():Promise<Record<string, Offer>> {
        try {
            const response = await this._client.get('/data/offers.json');
            // AppLogger.log(`retreive ${response.data.length} posts`);
            return response.data;
        }
        catch (e) {
            AppLogger.error(e);
            throw(e);
        }
    }

    public async loadServicesTree():Promise<SimpleNode> {
        try {
            const response = await this._client.get('/data/services.json');
            // AppLogger.log(`retreive ${response.data.length} posts`);
            return response.data;
        }
        catch (e) {
            AppLogger.error(e);
            throw(e);
        }
    }

    public async loadMetadata():Promise<Map<string, CriteriaMeta>> {
        try {
            const response = await this._client.get('/data/criteria_metadata.json');
            const criteriaMeta = new Map();
            response.data.forEach((e:RowMeta)=>{
                if (!criteriaMeta.has(e.critera)) {
                    criteriaMeta.set(e.critera, {name: e.critera, values: new Map(), coef:1.0, multiplier:0});
                }
                const meta = criteriaMeta.get(e.critera);
                if (meta) {
                    meta.multiplier = e.multiplier;
                    meta.coef = e.coef;
                    meta.values.set(e.value, e.desc);
                    meta.uuid = criteriaNameToUuid(e.critera);
                }
            });
            return criteriaMeta;
        }
        catch (e) {
            AppLogger.error(e);
            throw(e);
        }
    }

    public async loadCriteria(criteriaMeta:Map<string, CriteriaMeta>):Promise<Map<string, Criteria>> {
        try {
            const response = await this._client.get('/data/criteria_data.json');
            const rows:RowMapping[] = response.data;
            const criteria: Map<string, Criteria> = new Map();
            rows.forEach(element => {
                if (!criteria.has(element.critera)) {
                    const meta = criteriaMeta.get(element.critera);
                    let range = [0, 3];
                    if (meta) {
                        range = getRange(meta);
                    }
                    const uuid = criteriaNameToUuid(element.critera);
                    criteria.set(element.critera, {
                        name: element.critera,
                        value: 0,
                        range: range,
                        solutions: new Map(),
                        uuid: uuid ?? element.critera,
                    });
                }
                const c = criteria.get(element.critera);
                if (!c?.solutions.has(element.solution)) {
                    c?.solutions.set(element.solution, {
                        name: element.solution,
                        map: new Map()
                    });
                }
                c?.solutions.get(element.solution)?.map.set(element.value, element.active);
            });
            return criteria;
        }
        catch (e) {
            AppLogger.error(e);
            throw(e);
        }
    }

    public async loadPopup(uuid:string):Promise<PopupContent|undefined> {
        const route = `/data/popup/${uuid}.json`;
        try {
            const response = await this._client.get(route);
            const popupDTO = response.data as PopupDTO;
            if (!popupDTO["uuid"]) return;
            const popup:PopupContent = {
                uuid: popupDTO.uuid,                
                pages: [],
            };
            // load default pages 
            for (const p of popupDTO.default) {
                const page = await this.loadPage(p);
                popup.pages.push(page ?? notFoundService(uuid));
            }
            // 
            popup.offers = {};
            for (const key in popupDTO.offers) {
                const pages = popupDTO.offers[key];
                for (const p of pages) {
                    const page = await this.loadPage(p);
                    if (!popup.offers[key]) {
                        popup.offers[key] = [];
                    }
                    popup.offers[key].push(page ?? notFoundService(uuid));    
                }
            }
            return popup;
        }
        catch (e) {
            AppLogger.warn(`Impossible to load: ${route}`);
            return;
        }   
        return ;
    }

    public async loadPage(uuid:string):Promise<Service|undefined> {
        const route = `/data/popup/pages/${uuid}.json`;
        try {
            const response = await this._client.get(route);
            const service = response.data as Service;
            if (!service["uuid"]) return;
            return service;
        }
        catch (e) {
            AppLogger.warn(`Impossible to load: ${route}`);
        }   
        return;
    }

    public async loadTranslations(languages:string[]):Promise<Dictionary> {
        const result:Dictionary = {};
        for (const lang of languages) {
            const route = `/data/translations/${lang}.json`;
            try {
                const response = await this._client.get(route);
                result[lang] = response.data;                
            }
            catch (e) {
                AppLogger.warn(`Impossible to load: ${route}`);
            }               
        }
        return result;
    }
    
    public async loadMapping():Promise<Dictionary> {
        const route = `/data/mapping.json`;
        try {
            const response = await this._client.get(route);
            if (!response.data["offer"]) return {};
            return response.data;
        }
        catch (e) {
            AppLogger.warn(`Impossible to load: ${route}`);
        }               
        return {};
    }

}
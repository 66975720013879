import React, { useLayoutEffect, useRef } from "react";
import { useAppStore } from "../store/AppProvider";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CriteriaList from "../components/offer/CriteriaList";
import AircraftSelector from "../components/offer/AircraftSelector";
import { gsap } from "gsap";
import MapZone from "../components/offer/MapZone";
import { PopupTypes } from "../store/AppStore";

import "../components/movieclip/_movieclips.scss";
import "../components/debug/_DebugPanel.scss";
import "./_Offer.scss";
import "../components/offer/_items.scss";
import "../components/offer/_DisplayZone.scss";
import "../components/offer/_AirlineZone.scss";


export default observer(function Offer(): React.ReactElement {
    const appStore = useAppStore();
    const { t } = useTranslation("embed");
    const { uuid } = useParams<string>();
    const navigate = useNavigate();
    const pageRef = useRef(null);
    
    if (!uuid) {
        return <></>;
    }

    const offer = appStore.offers[uuid];
    if (!offer) {
        return <></>;
    }

    const handleReset = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.resetAll(); 
        navigate("/configurator");
    };    

    useLayoutEffect(()=>{
        gsap.context(()=>{

            const tl = gsap.timeline();          

            tl.set(".aircraft-selector", {opacity:0, x:"+100px", y:"-75px"});
            tl.set(".criteria-list", {opacity:0, x:"", y:"+150px"});
            tl.set(".satellite", {opacity:0, x:"", y:"-100px"});

            tl.set(".display-zone", {opacity:0, x:"+200px", y:""});
            tl.set(".airline-zone", {opacity:0, x:"-200px", y:""});
            
            tl.to(".airline-zone", {opacity:1, x:"", y:"", duration:0.6});
            tl.to(".display-zone", {opacity:1, x:"", y:"", duration:0.6}, 0.2);
            tl.to(".satellite", {opacity:1, x:"", y:"", duration:0.5}, 0.4);
            tl.to(".criteria-list", {opacity:1, x:"", y:"", duration:0.5}, 0.4);

            tl.to(".aircraft-selector", {opacity:1, x:"", y:"", duration:0.7}, 0.5);

        }, pageRef);
    }, []);

    
    // debugClip(".cabin-maintenance");


    const handleClickMap = (popup:string, origin:string) => {
        appStore.addPopup({
            type: PopupTypes.SERVICE,
            uuid: popup,
            offer: offer,
            zone: origin,
        });
    };

    //debugClip(".airline-place-tree");

    return (
        <div className={["offer", uuid].join(" ")} ref={pageRef}>

            <CriteriaList offer={offer}></CriteriaList>

            <AircraftSelector offer={offer} onClickMap={handleClickMap}></AircraftSelector>

            <MapZone 
                name="airline"
                offer={offer} 
                onClickMap={handleClickMap}
                zones={["factory", "certif", "customer"]}
            >
                <div className="airline-place"></div>
                <div className="airline-place-tower"></div>
                <div className="airline-place-tree"></div>
                <h1 className="ground-text prevent-select">{t("offer.airline")}</h1>
            </MapZone>

            <MapZone 
                name="display"
                offer={offer} 
                onClickMap={handleClickMap}
                zones={["pm", "dev", "ops", "sla"]}
            >
                <div className="display-place"></div>
                <h1 className="ground-text prevent-select">{t("offer.display")}</h1>
            </MapZone>

            <MapZone 
                name="space"
                offer={offer} 
                onClickMap={handleClickMap}
                zones={["satellite"]}
            >
            </MapZone>

            <button className="btn-reset" onClick={handleReset}>{t("common.buttons.reset").toUpperCase()}</button>
        </div>
    );
});

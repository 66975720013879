import { SimpleNode } from "../tools/tree";
import { Dictionary } from "../tools/types";

export interface AnimationState {
    x?: number,
    y?: number,
    blur?: number,
    scale?: number,
    z?: number,
    filter?: string,
    zIndex?: number,
}

export default interface Offer {
    uuid: string;
    weight: number,
    spreed_sheet_name: string,
    linefit: boolean,
    retrofit: boolean,
    style: {
        primary_color: string;
        secondary_color: string;
    },
    states?: Record<string, AnimationState>,
    services: {
        full: string[],
        medium: string[],
    },

}

export interface Item {
    type: string,
    src?: string,
    link?: string,
    style?: Dictionary,
}
export interface Grid {
    rows: number,
    columns: number,
    items: Item[],
}

export interface Service {
    uuid: string,
    node?: SimpleNode,
    locales: Record<string, Dictionary>,
    grid: Grid,
}

export interface SolutionRating {
    name: string,
    map: Map<number, number>
}


export enum CriteriaTypes {
    revenues = "Revenues objectives",
    aircraft_type = "Retrofit/Linefit",
    existing_solution = "Existing Solution",
    internet_speed = "Internet Speed",
    ui_custo = "UI Personalization",
    airline_control = "Airline Control Level"
}

export function criteriaNameToUuid(name: string): string | undefined {
    for (const key in CriteriaTypes) {
        const value = CriteriaTypes[key as keyof typeof CriteriaTypes];
        if (value === name) {
            return key;
        }
    }
    return;
}

export interface Criteria {
    uuid: string,
    name: string,
    value: number,
    range: number[]
    solutions: Map<string, SolutionRating>
}

export interface CriteriaMeta {
    name: string,
    values: Map<number, string>,
    coef: number,
    multiplier: number,
    uuid: string,

}
export interface SolutionScoreDetail {
    name: string,
    score: number,
    coef: number,
    multiplier: number
}
export interface SolutionScore {
    name: string;
    weigth: number,
    points: number
    pct: number,
    num_criteria: number,
    details: SolutionScoreDetail[]
}

export interface RowMapping {
    critera: string,
    solution: string,
    value: number,
    active: number,
    status: string
}

export interface RowMeta {
    critera: string,
    value: number,
    desc: string,
    coef: number,
    multiplier: number
}

export function getRange(meta: CriteriaMeta): number[] {
    const values = Array.from(meta.values.keys());
    let min = 0;
    let max = 0;
    values.forEach((e) => {
        if (e <= min) min = e;
        if (e >= max) max = e;
    });
    return [min, max];
}

export interface PopupDTO {
    uuid: string,
    offers?: Record<string, string[]>,
    default: string[]
}

export interface PopupContent {
    uuid: string,
    offers?: Record<string, Service[]>,
    pages: Service[]
}

export function notFoundOffer(): Offer {
    return {
        uuid: "not_found",
        weight: 0,
        linefit: true,
        retrofit: true,
        spreed_sheet_name: "",
        services: {
            full: [],
            medium: [],
        },
        style: {
            primary_color: "",
            secondary_color: "",
        }
    };
}

export function notFoundPopup(uuid:string): PopupContent {
    return {
        uuid: "not_found",
        pages: [notFoundService(uuid)]
    };
}

export function notFoundService(serviceUuid: string): Service {
    return {
        uuid: "not_found",
        locales: {
            "en": {
                title: "NOT FOUND",
                sub_title: `${serviceUuid}.json not found`,
                description: "Please check content json"
            }
        },
        grid: {
            rows: 0,
            columns: 0,
            items: []
        }
    };
}

export interface MappingLink {
    popup: string,
    offers: string[],
}



import React, { useEffect } from 'react';
import { Draggable } from "gsap/all";
import { gsap } from 'gsap';
import resolutionManager from '../../tools/ResolutionManager';
import { useAppStore } from '../../store/AppProvider';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import "./_AircraftTypeSelector.scss";

export type SwitchButtonProps = {
    selected: boolean,
    onChange?: (selected: boolean) => void,
};


export function SwitchButton({ selected, onChange }: SwitchButtonProps): React.ReactElement {
    const unSelectedPosition = { x: resolutionManager.scaleW(0), y: resolutionManager.scaleH(0) };
    const selectedPosition = { x: resolutionManager.scaleW(70), y: resolutionManager.scaleH(40) };
    const handleChange = (result: boolean) => {
        if (onChange) {
            onChange(result);
        }
    };

    useEffect(() => {
        gsap.set(".switch-button", !selected ? unSelectedPosition : selectedPosition);
        const switchButton = Draggable.create(".switch-button", {
            type: "x,y",
            liveSnap: {
                //x and y (or top and left) can each have their own array of values to snap to:
                points: [unSelectedPosition, selectedPosition],
                radius: 1500
            }
        })[0];
        const handleSwitch = () => {
            const diff = Math.abs(switchButton.x - selectedPosition.x);
            handleChange(diff < 0.5);
        };
        const handleClick = () => {
            handleChange(!selected);
        };
        if (switchButton) {
            switchButton.addEventListener("dragend", handleSwitch);
            switchButton.addEventListener("release", handleClick);
        }
        return () => {
            if (switchButton) {
                switchButton.removeEventListener("dragend", handleSwitch);
                switchButton.removeEventListener("release", handleClick);
            }
        };

    }, [selected]);
    /*onClick={() => handleChange(!selected)}*/
    return (
        <div className="switch">
            <div className="switch-bg"></div>
            <div className="switch-button" ></div>
        </div>
    );
}

export default observer(function AircraftTypeSelector(): React.ReactElement {

    const appStore = useAppStore();
    const { t } = useTranslation("embed");

    //debugClip(".switch-left-label");
    //debugClip(".aircraft-type-selector");

    const handleChange = (state: boolean) => {
        appStore.selectTypeAircraft(state);
    };

    const lineFitClass = appStore.linefit ? "selected" : "";
    const retroFitClass = appStore.linefit ? "" : "selected";
    return (
        <div className="aircraft-type-selector prevent-select">
            <SwitchButton selected={appStore.linefit} onChange={handleChange}></SwitchButton>
            <h1 className={["switch-left-label", retroFitClass].join(" ")}>{t("criteria.aircraft_type.value_0.title")}</h1>
            <h1 className={["switch-right-label", lineFitClass].join(" ")}>{t("criteria.aircraft_type.value_1.title")}</h1>
        </div>
    );
});

import React, { useEffect, useRef } from "react";

import "./_PodiumNav.scss";
import { useAppStore } from "../../store/AppProvider";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Offer from "../../store/Offer";
import PoidumButton from "./PodiumButton";
import { OfferNavProps } from "../circles/OfferNav";
import { gsap } from "gsap";

const PodiumNav = observer(function PodiumNav({ selectedOffer, orderedOffers, showBar, onChange }: OfferNavProps): React.ReactElement {
    const appStore = useAppStore();
    const { t } = useTranslation("embed");
    const mainRef = useRef(null);
    const previousPositions = useRef<Record<string, DOMRect>>({});

    const handleChange = (offer: Offer): void => {
        if (onChange) {
            onChange(offer);
        }
    };

    useEffect(() => {
        gsap.set(".offer-nav", { y: "+=100px", opacity: 0 });
        gsap.to(".offer-nav", { y: "0", opacity: 1});
        
        //const uuid = orderedOffers[0];
        //gsap.to(`.offer-buton.${uuid}`, {scale:1.2});

    }, []);

    const buttons = orderedOffers.map((offerUuid, idx) => {
        const offer = appStore.offers[offerUuid];
        const isSelected = selectedOffer && selectedOffer.uuid === offer.uuid;
        const isFirst = idx === 0; 
        const className =  [];
        className.push(isSelected?"selected":"");
        className.push(isFirst?"first":"");

        const handleClick = (event:React.MouseEvent) => {
            event.stopPropagation();
            handleChange(offer);
        };

        return <PoidumButton
            className={className.join(" ")}
            offer={offer}
            position={idx}
            key={offer.uuid}
            onClick={handleClick}></PoidumButton>;
    });
    const title = selectedOffer ? `offers.${selectedOffer.uuid}.title` : "circles.title"; // 

    return (
        <div className="offer-nav podium-nav" ref={mainRef}>
            <div className="offer-nav-buttons prevent-select">
                <div className={["nav-title", selectedOffer ? selectedOffer.uuid : ""].join(" ")}>{t(title).toUpperCase()}</div>
                { buttons }
            </div>
        </div>
    );
});

export default PodiumNav;


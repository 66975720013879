import React from 'react';
import { useAppStore } from '../../store/AppProvider';
import Offer, { Criteria } from '../../store/Offer';
import { cssToRGBA, getOfferColor, rgbaToCSS } from '../../tools/styles';
import ToggleClip, { ToggleState } from '../movieclip/ToggleClip';
import BrickGroup from '../movieclip/BrickGroup';

import "./_CriteriaList.scss";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferButton } from '../circles/OfferNav';


interface MiniCriteriaProps {
    criteria:Criteria,
    value:number,
}


const MiniCriteria = function CriteriaList({criteria, value}:MiniCriteriaProps):React.ReactElement {
    const classNames = ["criteria", criteria.uuid];
    return (
        <div className="mini-criteria">
            <BrickGroup state={ToggleState.unselected} numBrick={value+1}></BrickGroup>
            <ToggleClip className={classNames.join(" ")} state={ToggleState.unselected}></ToggleClip>
        </div>
    );
};

interface CriteriaListProps {
    offer:Offer,
}


export default function CriteriaList({offer}:CriteriaListProps):React.ReactElement {
    const appStore = useAppStore();
    const navigate = useNavigate();
    const { t } = useTranslation("embed");
    const [searchParams] = useSearchParams();
    const isFromConfig = searchParams.get("origin") === "/configurator";
    
    const criterias = appStore.criteriaToDiplay();
    const items = criterias.map(c => {
        const criteria = appStore.criteria.get(c);
        if (criteria) {
            const value = appStore.criteriaValues.get(criteria.name) ?? -1; 
            return <MiniCriteria key={criteria.uuid} criteria={criteria} value={value}></MiniCriteria>;    
        }
    });


    const color = getOfferColor(offer);
    const rgba = cssToRGBA(color)[0];
    rgba.a = 0.1;

    const handleClick = (event:React.MouseEvent) => {
        event.stopPropagation();
        if (isFromConfig) {
            navigate("/configurator");
        }
        else {
            navigate("/circles");
        }
    };
    
    return (
        <div className="criteria-list" style={{borderColor:color, backgroundColor:rgbaToCSS(rgba)}} onClick={handleClick}>
            <OfferButton offer={offer} key={offer.uuid}></OfferButton>
            {
                isFromConfig ? 
                    <div className="criteria-items">
                        {items}
                    </div>
                    :
                    <h1 className={["title", offer.uuid].join(" ")}>{t(`offers.${offer.uuid}.title`)}</h1>
            }
        </div>
    );
}


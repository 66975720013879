import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import "./_Header.scss";

interface NavItem {
    pathname: string,
    className: string,
}

const Header = function Header(): React.ReactElement {

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isFromConfig = searchParams.get("origin") === "/configurator";


    const isInFav = location.hash === "#bookmarks";
    const bookPath = isInFav ? location.pathname:location.pathname+"#bookmarks";

    const navItems:Record<string, NavItem[]> = {
        "circle": [{pathname: bookPath, className:"bookmark"}, {pathname: "/configurator", className:"home"}],
        "configurator": [{pathname: bookPath, className:"bookmark"}, {pathname: "/circles", className:"circles"}],
        "offer": [  {pathname: bookPath, className:"bookmark"}, {pathname: isFromConfig ? "/circles":"/configurator", className:isFromConfig ? "circles":"home"}],
    };
    let links:NavItem[] = [];
    for (const key in navItems) {
        const re = new RegExp(key);
        if (location.pathname.match(re)) {
            links = navItems[key];
        }
    }
    
    const items = links.map((l, i) => {
        const handleClick = (event:React.MouseEvent) => {
            event.stopPropagation();
        };
        if (l.pathname !== location.pathname || l.className === "bookmark")  {
            return <Link key={i} to={l} onClick={handleClick}>
                <img className={l.className}></img>
            </Link>;
        }
    });

    return (
        <header>
            <Link to="/">
                <img className="logo"></img>
            </Link>
            <div className="nav">
                { items }
            </div>
        </header>
    );

};

export default Header;
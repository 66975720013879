
import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "../store/AppProvider";
import CriteriaSelector from "../components/configurator/CriteriaSelector";
import Offer from "../store/Offer";
import { useTranslation } from "react-i18next";
import AircraftTypeSelector from "../components/configurator/AircraftTypeSelector";
import { ToggleState } from "../components/movieclip/ToggleClip";
import ConfiguratorProgress from "../components/configurator/ConfiguratorProgress";
import { gsap } from "gsap";

import "./_Configurator.scss";
import "../components/debug/_DebugPanel.scss";
import "../components/movieclip/_movieclips.scss";
import { PopupTypes } from "../store/AppStore";
import PodiumNav from "../components/configurator/PodiumNav";



const Configurator = observer(function Configurator(): React.ReactElement {
    const appStore = useAppStore();
    const { t } = useTranslation("embed");
    const pageRef = useRef(null);

    const handleClickOffer = (offer: Offer): void => {
        appStore.addPopup({
            type: PopupTypes.OFFER,
            offer: offer,
            uuid: offer.uuid,
        });
        
        const s = appStore.getScoreForOffer(offer);
        if (s) {
            console.log("----------------------------------");
            console.log(`Solution [${s.name}] pts:${s.points} nc:${s.num_criteria}`);
            console.log("----------------------------------");
            s.details.forEach((d) => {
                console.log(`[${d.name}] score:${d.score} coef:${d.coef} multiplier:${d.multiplier}`);
            });
        }
    };

    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        appStore.resetAll();
    };

    useLayoutEffect(() => {
        gsap.context(() => {

            const tl = gsap.timeline();

            tl.set(".aircraft-ground", { opacity: 0, y: "-200px" });
            tl.set(".aircraft-ground-shadow", { opacity: 0, x: "-50px" });
            tl.set(".config-progress", { opacity: 0, x: "-50px", y: "25px" });
            tl.set(".aircraft-type-selector", { opacity: 0, y: "-25px" });
            tl.set(".aircraft", { opacity: 0, x: "+50px", y: "-25px" });
            tl.set(".aircraft-shadow", { opacity: 0, x: "+50x", y: "-25px" });
            for (let i = 0; i < 5; i++) {
                tl.set(`.criteria-${i}`, { opacity: 0, x: "-50px", y: "-25px" });
            }
            // 
            tl.to(".aircraft-ground", { opacity: 1, y: "" }, 0.2);
            tl.to(".aircraft-ground-shadow", { opacity: 1, x: "" }, 0.2);
            tl.to(".config-progress", { opacity: 1, x: "", y: "" }, 0.6);
            tl.to(".aircraft-type-selector", { opacity: 1, y: "" }, 0.4);

            tl.to(".aircraft", { opacity: 1, duration: 0.4, x: "", y: "" }, 0.8);
            tl.to(".aircraft-shadow", { opacity: 1, duration: 0.4, x: "", y: "" }, 0.8);
            //tl.to(".criteria-selector", {opacity:1, x:"", y:"", duration:0.4}, 0.5);
            for (let i = 0; i < 5; i++) {
                tl.to(`.criteria-${i}`, { opacity: 1, duration: 0.15, delay: i * 0.1, zIndex: 25 - i }, 0.4);
                tl.to(`.criteria-${i}`, { x: "", y: "", delay: i * 0.1 }, 0.4);
            }

        }, pageRef);
    }, []);

    const criterias = appStore.criteriaToDiplay();

    //debugClip(".criteria-0");

    const selectors = criterias.map((c, idx) => {
        const left = 906 + idx * 247;
        const top = 1353 - idx * 146;
        const value = appStore.criteriaValues.get(c) ?? 0;
        const state = value !== -1 ? ToggleState.selected : ToggleState.unselected;
        const classNames = ["criteria-selector", `criteria-${idx}`].join(" ");
        const delays = {
            bricks: 1.0,
        };
        return (<CriteriaSelector
            key={c}
            className={classNames}
            style={{ left, top }}
            criteria={appStore.criteria.get(c)}
            state={state}
            value={value}
            delays={delays}
        >
        </CriteriaSelector>);
    });

    return (
        <div className="full-screen configurator" ref={pageRef}>
            {/*<div className="debug-configurator-bg" style={{ visibility: "hidden" }}></div>*/}
            <div className="aircraft-ground"></div>
            <div className="aircraft-ground-shadow"></div>
            <div className="aircraft configurator" style={{ visibility: "visible" }}></div>
            <div className="aircraft-shadow" style={{ visibility: "visible" }}></div>
            <AircraftTypeSelector></AircraftTypeSelector>
            {selectors}
            <ConfiguratorProgress pct={appStore.getPctComplete()}></ConfiguratorProgress>
            {appStore.getPctComplete() === 1 ?
                <PodiumNav showBar={false} onChange={handleClickOffer} orderedOffers={appStore.getOrderOfferList()}></PodiumNav>
                : <></>
            }
            <button className="btn-reset" onClick={handleReset}>{t("common.buttons.reset").toUpperCase()}</button>
        </div>
    );
});

//  <OfferNav showBar={false} onChange={handleClickOffer} orderedOffers={appStore.getOrderOfferList()}></OfferNav>

export default Configurator;
import React, { useLayoutEffect, useRef } from "react";
import BrickGroup from "../../movieclip/BrickGroup";
import { ToggleState } from "../../movieclip/ToggleClip";

import "../_Popup.scss";
import "./_BrickSelector.scss";
import resolutionManager from "../../../tools/ResolutionManager";
import { gsap } from "gsap";

export interface BrickSelectorProps {
    onSelectBrick?:(num:number)=>void;
    selected:number,
}

// import { AnimateElementHandle } from "../../movieclip";
// const BrickSelector = React.forwardRef<AnimateElementHandle, BrickSelectorProps>(function BrickSelector({onSelectBrick, selected}:BrickSelectorProps, ref: React.Ref<unknown>): React.ReactElement {

const BrickSelector = function BrickSelector({onSelectBrick, selected}:BrickSelectorProps): React.ReactElement {

    const bricks = [];
    const main = useRef(null);

    const handleSelectBlock = (num:number):void => {
        if (onSelectBrick) {
            onSelectBrick(num);
        }
    };

    useLayoutEffect(()=>{
        gsap.context(()=> {
            for (let i=0; i < 4; i++) {
                if (i === selected) {
                    gsap.to(`.bg-${i}`, {scale:1.4, zIndex:2, duration:0.2});
                }
                else {
                    gsap.to(`.bg-${i}`, {scale:1.0, zIndex:1, duration:0.1});
                }
            }    
        }, main);
    }, [selected]);

    // selected
    for (let i=0; i < 4; i++) {

        const isSelected = selected === i;
        //const scale = isSelected ? "1.4":"1.0";
        const scale = "1.0";
        const zIndex = isSelected ? 2:1;
        const state = selected === i ? ToggleState.selected:ToggleState.unselected;
        const left = resolutionManager.scaleW(i*260);
        const handleClick = (event:React.MouseEvent):void =>{
            event.stopPropagation();
            handleSelectBlock(i);
        };
        bricks.push(
            <div key={i} className={["group-with-wall", `bg-${i}`].join(" ")} style={{scale:scale, zIndex:zIndex, left:left}} onClick={handleClick}>
                <BrickGroup numBrick={i+1} state={state}  useWall={false}></BrickGroup>
            </div>
        );
    }

    return (
        <div className="brick-selector" ref={main}>
            { bricks }
        </div>
    );
};

export default BrickSelector;
    
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useAppStore } from "../../store/AppProvider";
import ToggleClip, { ToggleState } from "../movieclip/ToggleClip";
import { gsap } from "gsap";
import { ZoneProps } from ".";


interface MapZoneProps extends ZoneProps {
    zones: string[],
    name: string,
    children?:React.ReactNode,
}

export default observer(function MapZone({ name, children, offer, onClickMap, zones }: MapZoneProps): React.ReactElement {
    const appStore = useAppStore();
    const selected = useRef<Record<string, string>>({});


    const handleClickMap = (popup: string, newFocus: string) => {

        startFocus(popup, newFocus);

        if (onClickMap) {
            onClickMap(popup, name);
        }
    };


    useEffect(() => {
        //
        if (appStore.popupList.length) {
            // focus toujours actif ?
            for (const k in selected.current) {
                if (!appStore.isPopupOpen(k)) {
                    stopFocus(k, selected.current[k], 0.2);
                }
            }
        }
        else {
            stopAllFocus(0);
        }
    }, [appStore.popupList]);


    const stopAllFocus = (duration:number = 0) => {
        for (const k in selected.current) {
            stopFocus(k, selected.current[k], duration);
        }
    };

    const stopFocus = (popup: string, focus:string, duration:number = 0) => {
        if (selected.current[popup]) { 
            gsap.killTweensOf(focus);
            gsap.to(focus, { opacity: 0, duration: duration, onComplete: () =>{
                delete selected.current[popup];
            } });                    
        }
    };

    const startFocus = (popup: string, focus:string) => {
        if (!selected.current[popup]) {
            gsap.set(focus, { visibility: "visible", opacity: 0, scale:1.0 });
            gsap.to(focus, { opacity: 1, duration: 0.3 });
            gsap.to(focus, { scale: 1.1, repeat:-1, yoyo:true, duration: 0.3 });    
        }
        selected.current[popup] = focus;
    };

    const items = zones.map(k => {
        const popup = appStore.getMappingPopup(`offer.${name}.${k}`, offer);
        const handleClick = (event:React.MouseEvent) => {
            event.stopPropagation();
            if (popup) handleClickMap(popup, `.${k} .zone-focus-1`);
        };
        return (
            <ToggleClip
                key={k}
                className={k}
                state={popup ? ToggleState.selected : ToggleState.unselected}
                onClick={handleClick}
            >
                <div className="zone-focus-1"></div>
            </ToggleClip>
        );
    });

    // debugClip(".display-zone .ground-text");
    
    return (
        <div className={`${name}-zone`}>
            { items }
            { children }                    
        </div>
    );
});
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./store/AppProvider";
import Router from "./Router";
import AppStore from "./store/AppStore";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";

import { gsap } from "gsap";
import { Draggable} from "gsap/all";
gsap.registerPlugin(Draggable);

// init appStore (avoid singleton pattern)
const appStore = new AppStore();
appStore.start();


function App(): JSX.Element {

    function updateCustomCSSProperties() {
        if (isDesktop) {
            if (window.innerWidth < 980) {
                document.documentElement.classList.add("app-device-desktop-minified");
            } else {
                document.documentElement.classList.remove("app-device-desktop-minified");
            }
        }
    }
    
    useEffect(() => {

        // css
        let appDeviceClass = "";
        if (isMobileOnly) {
            appDeviceClass = "app-device-smartphone";
        } else if (isTablet) {
            appDeviceClass = "app-device-tablet";
        } else if (isDesktop) {
            appDeviceClass = "app-device-desktop";
        }

        document.documentElement.classList.add(appDeviceClass);
        updateCustomCSSProperties();
        window.addEventListener("resize", () => {
            updateCustomCSSProperties();
        });
        return () => window.removeEventListener("resize", updateCustomCSSProperties);
    }, []);

    return (
        <AppProvider store={appStore}>
            <BrowserRouter>
                <Router></Router>
            </BrowserRouter>
        </AppProvider>
    );
}

export default App;


import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "../store/AppProvider";
import BrickGroup from "../components/movieclip/BrickGroup";
import ToggleClip from "../components/movieclip/ToggleClip";
import CriteriaSelector from "../components/configurator/CriteriaSelector";
import { CriteriaTypes } from "../store/Offer";

import "./_Home.scss";

const AnimationPlayGround = observer(function AnimationPlayGround(): React.ReactElement {
    const appStore = useAppStore();

    const [test, setTest] = useState("unselect");
    const [animation, setAnimation] = useState<string>("");

    const handleClick = () => {
        setTest(test === "unselected" ? "selected":"unselected");
    };

    const handleAnimate = () => {
        setAnimation("open");
    };
    
    console.log(animation);

    return (
        <div className="anim-playground">
            
            <ToggleClip className="brick" style={{left:0, top:400}} state={test}></ToggleClip>

            <ToggleClip className="criteria revenues" style={{left:400, top:600}} state={test}></ToggleClip>

            <BrickGroup style={{left:600, top:400}} state={test} numBrick={5}></BrickGroup>
            
            <CriteriaSelector value={0} className="criteria-selector" style={{left:400, top:800}} criteria={appStore.criteria.get(CriteriaTypes.existing_solution)} state={test}></CriteriaSelector>

            <CriteriaSelector value={0} className="criteria-selector" style={{left:700, top:800}} criteria={appStore.criteria.get(CriteriaTypes.airline_control)} state={test}></CriteriaSelector>

            <CriteriaSelector value={0} className="criteria-selector" style={{left:1000, top:800}} criteria={appStore.criteria.get(CriteriaTypes.internet_speed)} state={test}></CriteriaSelector>

            <div>
                <button className="button" onClick={handleClick}>Test</button>
                <button className="button" onClick={handleAnimate}>Test</button>
            </div>
        </div>
    );
});

export default AnimationPlayGround;
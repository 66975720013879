export interface RGB {
    r: number,
    g: number,
    b: number,
}
export interface RGBA extends RGB {
    a: number,
}

export function dec2hex(i:number):string {
    const hex = i.toString(16).toUpperCase();
    const padLength = hex.length % 2 == 0 ? hex.length: hex.length+1;
    return hex.padStart(padLength, "0");
}

export function hex2dec(hex:string): number {
    return parseInt(hex, 16);
}

export function rgbaToHex(color:RGBA|RGB):string {
    return `#${dec2hex(color.r)}${dec2hex(color.g)}${dec2hex(color.b)}`;
}

export function rgbaToString(color:RGBA|RGB):string {
    return `#${dec2hex(color.r)}${dec2hex(color.g)}${dec2hex(color.b)}`;
}


export function hexToRGBA(hex:string):RGBA {
    if (hex.length === 3) {
        return {
            r: hex2dec(hex[0]),
            g: hex2dec(hex[1]),
            b: hex2dec(hex[2]),
            a: 1.0
        };
    } else if (hex.length === 6) {
        return {
            r: hex2dec(hex.substring(0, 2)),
            g: hex2dec(hex.substring(2, 4)),
            b: hex2dec(hex.substring(4, 6)),
            a: 1.0
        };
    }
    return {r:255, g:255, b:255, a:1};
}

export function normalizeRGBA(color:RGBA|RGB):RGBA {
    const a = 'a' in color ? color.a:100;
    return {
        r: color.r / 255,
        g: color.g / 255,
        b: color.b / 255,
        a: a / 100,
    };
}

export function developRGBA(color:RGBA|RGB):RGBA {
    const a = 'a' in color ? color.a:1;
    return {
        r: Math.floor(color.r * 255),
        g: Math.floor(color.g * 255),
        b: Math.floor(color.b * 255),
        a: Math.floor(a * 100),
    };
}



import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import "./_Home.scss";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../store/AppProvider";


// export const AudioProgressBar = observer(function AudioProgressBar() {
const Home = observer(function Home(): React.ReactElement {

    const {t} = useTranslation("embed");
    const appStore = useAppStore();
    const [fullscreen, setFullScreen] = useState(document.fullscreenElement !== null);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setFullScreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setFullScreen(false);
        }
    };

    const handleReset = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.resetAll();
    };

    return (
        <div className="home">
            <h1>{t("home.welcome")}</h1>
            <Link to={"/configurator"} className="btn-primary prevent-select">
                {t("home.discover")}
            </Link>
            
            <button className="btn-primary prevent-select fs" onClick={toggleFullScreen}>{t( fullscreen ? "home.exit_full_screen":"home.go_full_screen")}</button>

            <button className="btn-reset" onClick={handleReset}>{t("common.buttons.reset").toUpperCase()}</button>
        </div>
        
    );
});

export default Home;












import React, { useLayoutEffect, useState } from "react";
import { useAppStore } from "../../store/AppProvider";
import { gsap } from "gsap";
import resolutionManager from "../../tools/ResolutionManager";

import "./_Popup.scss";
import { observer } from "mobx-react-lite";
import { PopupProps } from "../../store/AppStore";
import PopupFactory from "./PopupFactorty";
import { Draggable } from "gsap/all";

export interface PopupDefaultProps {
    children?: React.ReactNode,
    onClose?:()=>void,
    useNav?:boolean,    
    useFavorite?:boolean,
    popup: PopupProps,
}

export interface PopupContentProps {
    popup: PopupProps,
    onClose?:()=>void,
    onLoad?:()=>void,
}


export interface PopupActionProps {
    popup: PopupProps,
    useFavorite?: boolean,

}

const PopupAction = observer(function PopupAction({popup, useFavorite}:PopupActionProps): React.ReactElement {
    
    const appStore = useAppStore();

    const isFavorite = appStore.isFavorite(popup.uuid);
    const favClass = ["btn-add-favorite", isFavorite?"selected":""];

    const handleAddtoFavorite = (event:React.MouseEvent) => {
        event.stopPropagation();       
        appStore.toggleFavorite(popup);
    };

    const handleClose = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.removePopup(popup.uuid);
    };
    
    return (
        <div className="actions">
            { useFavorite ? <button className={favClass.join(" ")} onClick={handleAddtoFavorite}></button>:<></> }                    
            <button className="btn-close" onClick={handleClose}></button>
        </div>  
    );
});


export default observer(function Popup({popup, children, useFavorite}:PopupDefaultProps): React.ReactElement {
    

    const appStore = useAppStore();
    const [loaded, setLoaded] = useState(false);
    //const drag = Draggable.create(target, { type: "left,top" })[0];

    useLayoutEffect(()=> {
        const selector = `.popup.${popup.uuid}`;
        const decay = resolutionManager.scaleW(800);
        gsap.set(selector, {opacity:0, x:`+${decay}px`});
        const item = Draggable.create(selector, { 
            type: "left,top", 
            zIndexBoost: false, 
            trigger: `.dragZone.${popup.uuid}`,
        })[0];
        const handleStartDrag = () => {
            gsap.set(selector, {zIndex: 3000 + appStore.popupZIndex++});            
        };
        item.addEventListener("dragstart", handleStartDrag);
        return () => {
            // 
            item.removeEventListener("dragstart", handleStartDrag);
            item.kill();
        };
    }, []);

    useLayoutEffect(()=> {
        if (loaded) {
            const decay = (appStore.popupList.length  - 1) * resolutionManager.scaleW(40);
            const selector = `.popup.${popup.uuid}`;
            gsap.set(selector, {opacity:1});
            gsap.to(selector, { x:`-${decay}`, y:`+${decay}`, zIndex:3000 + appStore.popupList.length});    
        }
    }, [loaded]);

    const handleClick = (event:React.MouseEvent) => {
        event.stopPropagation();
    };

    const handleOnLoad = () => {
        setLoaded(true);
    };

    const content = PopupFactory.build(popup, handleOnLoad);

    return (

        <div className={["popup", popup.uuid].join(" ")} onClick={handleClick}>
            <section className="popup-content">
                <PopupAction useFavorite={useFavorite} popup={popup}></PopupAction>
                <div className={["dragZone", popup.uuid].join(" ")}></div>    
                { content }     
                { children }
            </section>
        </div>
    );
});


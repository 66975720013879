import { gsap } from "gsap";
import { mapRange } from "gsap/all";
import { observer } from "mobx-react-lite";
import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

interface ConfiguratorProgressProps {
    pct: number,
}

export default observer(function ConfiguratorProgress({pct}:ConfiguratorProgressProps): React.ReactElement {
    const { t } = useTranslation("embed");    
    const pctStyle = `${mapRange(100, 16, 20, 90, pct*100)}%`;
    /*
    const style:React.CSSProperties = {
        "--complete-pct": pctStyle,
    } as React.CSSProperties;   
    */
    useLayoutEffect(()=> {

        gsap.to(".complete", {"--complete-pct": pctStyle, duration: 0.5});
        if (pct === 1) {
            //gsap.set(".complete", {scale: 1.05});
            gsap.to(".complete", {opacity:1, delay:0.2});
        }
    }, [pct]);

    return (
        <div className="config-progress prevent-select">
            <h1>{t("configurator.configuration")}</h1>
            <h1 className="complete">{t("configurator.complete")}</h1>
        </div>
    );
});

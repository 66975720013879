import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Point, getDistance, getPointOnSegment, getPolygonCentroid, getRingMediumSize, getRingPath, getRingPoints } from "../../tools/geom";
import { gsap } from "gsap";
import Offer from "../../store/Offer";
import { useTranslation } from "react-i18next";
import { wordWrap, wrapper, getRingColor } from "../../tools/styles";
import { useAppStore } from "../../store/AppProvider";
import { SimpleNode, buildLevels } from "../../tools/tree";
import { observer } from "mobx-react-lite";
import { pathToPoint, pointsToSVGCircles } from "../../tools/svg";
import { Draggable } from "gsap/all";
import resolutionManager from "../../tools/ResolutionManager";
import { PopupTypes } from "../../store/AppStore";

import "./_SunBurst.scss";
import { Dictionary } from "../../tools/types";

export interface SunburstProps {
    offer: Offer,
    tree: SimpleNode,
    children?: React.ReactNode,
    selected?: boolean,
    style: SunburstStyle,
    divStyle?:React.CSSProperties,
    delays?:Dictionary,
}

interface TextCellOptions {
    reverse: boolean,
    fontSize: number,
    fontColor: string,
    fontFace: string,
}

export interface SunburstStyle {
    innerRadius: number,
    outerRadius?: number,
    strokeWidth?: number,
    strokeColor?: string,
    center?: {
        x: number,
        y: number,
    },
    arcSpacing?: number,
    centerText: TextCellOptions,
    leaftText: TextCellOptions,
}

function getLinesForRing(text: string, maxWith: number, fontSize: number, fontFace: string, charMargin: number = 4) {
    const textSize = wrapper.getTextWidth(text, fontSize, fontFace);
    const charSize = textSize / text.length;
    const maxCharByLine = Math.round(maxWith / charSize) - charMargin;
    const lines = wordWrap(text, maxCharByLine).split("\n");
    if (lines.length === 1) {
        return [{ text: text, size: textSize }];
    }
    return lines.map(l => {
        return { text: l, size: wrapper.getTextWidth(l, fontSize, fontFace) };
    });
}

export const debugLeaf = (cellId: string, childPoints: Point[], list: JSX.Element[], outer: number):void => {
    if (cellId === "pax-0-1") {
        //childSComponents.push(textInCell(childPoints, cellId, "some text here\nvery long so it's not good", outer, false));
        //childSComponents.push(textInCell(childPoints, cellId, "some text here very long", outer, false));
        const centroid = getPolygonCentroid(childPoints);
        const [a, b, c, d] = childPoints;
        const a1 = getPolygonCentroid([a, b]);
        const a2 = getPolygonCentroid([c, d]);
        const l = getPointOnSegment(a, b, 2 / 3);
        const r = getPointOnSegment(d, c, 2 / 3);

        list.push(...pointsToSVGCircles([centroid, a1, a2, l, r], 5, "#000"));
        list.push(...pointsToSVGCircles(childPoints, 5, "#000"));
        list.push(pathToPoint(childPoints[0], childPoints[3], outer));
    }
};

export function textInCell(points: Point[], cellId: string, text: string, textOptions: TextCellOptions, reverse: boolean = false): JSX.Element {
    // determine how many lines 

    const defaultOptions: TextCellOptions = {
        reverse: false,
        fontSize: 28,
        fontColor: "rgba(255,255,255,1.0)",
        fontFace: "Open Sans",
    };

    const options = { ...defaultOptions, ...textOptions };
    const ringMediumSize = getRingMediumSize(points);
    const linesForRing = getLinesForRing(text, ringMediumSize, options.fontSize, options.fontFace, 4);
    const numLines = linesForRing.length;

    const defs = [];
    const texts = [];
    const pct = [
        [0.5],
        [0.6, 0.4],
        [0.7, 0.5, 0.3],
    ];
    const [a, b, c, d] = points;
    for (let i = 0; i < numLines; i++) {
        const line = reverse ? linesForRing[numLines-i-1]:linesForRing[i];
       
        const p = pct[numLines - 1][i];
        const l = getPointOnSegment(a, b, p); // (i+1)/(numLines+1)
        const r = getPointOnSegment(d, c, p); // (i+1)/(numLines+1)
        const left = reverse ? r : l;
        const right = reverse ? l : r;
        const r2 = getDistance(l, { x: 0, y: 0 });
        const maxWidth = getDistance(l, r);
        const pd = `M${left.x} ${left.y} A${r2} ${r2} 0 0 ${reverse ? 0 : 1} ${right.x} ${right.y}`;
        const path = <path key={`text-path-${cellId}-${i}`} id={cellId + "" + i} d={pd}></path>;
        defs.push(path);

        const dx = (maxWidth - line.size) / 2;
        texts.push(
            <text key={`text-${cellId}-${i}`} x={0} y={0} style={{ fill: options.fontColor, fontSize: `${options.fontSize}px` }} className="svgText" visibility={"hidden"}>
                <textPath xlinkHref={`#${cellId + "" + i}`} method="align" startOffset={dx}>{line.text}</textPath>
            </text>
        );
    }
    return (
        <g key={`group-text-${cellId}}`}>
            <defs>
                {defs}
            </defs>
            {texts}
        </g>
    );
}



const Sunburst = observer(function Sunburst({ style, selected, offer, tree, divStyle, delays }: SunburstProps): React.ReactElement {

    const { t } = useTranslation("embed");
    const appStore = useAppStore();
    const sunBurstContext = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useLayoutEffect(()=> {
        const initialState = selected ? "open" : "close";
        const state = { ...offer.states?.[initialState] };            
        if (state) {
            const x = selected ? 1182 : state.x ?? 0;
            const y = selected ? 100 : state.y ?? 0;
            state.x = resolutionManager.scaleW(x);
            state.y = resolutionManager.scaleH(y);    
            state.zIndex = selected ? 102:divStyle?.zIndex as number;
            gsap.context(() => {
                const delay = delays?.["open"] ?? 0;
                gsap.set(sunBurstContext.current, { ...state, opacity: 0, scale:0});
                if (selected) {
                    gsap.to(sunBurstContext.current, { opacity: 1, scale: 1.0, delay, onComplete: () => {
                        gsap.set(".svgText", { visibility: "hidden", opacity: 0 });        
                        gsap.to(".svgText", { visibility: "visible", opacity: 1, duration: 0.3, delay:0.2 });        
                        setIsOpen(true);
                    } });

                } else {
                    gsap.to(sunBurstContext.current, { ...state, opacity: 1, delay, onComplete: () => {
                        setIsOpen(true);
                    } });
                }
            }, sunBurstContext);
        }
        // 1708x1708 
    }, []);

    useLayoutEffect(() => {
        if (!isOpen) return;
        gsap.context(() => {
            const initialState = selected ? "open" : "close";
            const state = { ...offer.states?.[initialState] };            
            if (state) {
                if (initialState === "open") {
                    state.x = resolutionManager.scaleW(1182);
                    state.y = resolutionManager.scaleH(100);    
                }
                else {
                    state.x = resolutionManager.scaleW(state.x ?? 0);
                    state.y = resolutionManager.scaleH(state.y ?? 0);    
                }
            }
            state.zIndex = selected ? 102:divStyle?.zIndex as number;
            const animation = {
                ...state, onComplete: () => {
                    if (selected) {
                        gsap.to(".svgText", { visibility: "visible", opacity: 1, duration: 0.3 });
                    }
                }
            };
            gsap.to(sunBurstContext.current, { ...animation, opacity: 1 });

        }, sunBurstContext);
    }, [selected]);

    const handleClick = (event: React.MouseEvent<SVGElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!selected) {
            selectOffer();
        }
    };

    const handleClickLeaf = (node: SimpleNode) => {
        if (!selected) {
            selectOffer();
            return;
        }
        if (appStore.isServiceInOffer(offer, node.uuid)) {
            appStore.addPopup({ type: PopupTypes.SERVICE, uuid: node.popup ?? "unknown", offer: offer });
        }
    };

    const selectOffer = () => {
        gsap.to(".svgText", {
            opacity: 0, duration: 0.2, onComplete: () => {
                appStore.setSelectedOffer(offer);
            }
        });
    };

    const levels = useMemo(() => buildLevels(tree), [tree]);


    const buildSVG = () => {
        const leafLevel = 360 / levels[levels.length - 1].length;
        let startAngle = 0;
        let endAngle = 0;
        const inner = style.innerRadius;
        const outer = style.outerRadius ?? inner * 2;
        const levelGroth = outer - inner;
        const childMargin = levelGroth * 1 / 100;

        // console.log(inner, outer, levelGroth, childMargin);

        //let selectedPath = null;
        const strokeWidth = style.strokeWidth ?? 10;
        const strokeColor = style.strokeColor ?? "white";
        const size = 2 * inner + 2 * outer + 3 * childMargin + 20;
        //const center = {x: size/2, y: size/2};
        const center = style.center ?? { x: 0, y: 0 };
        const groups = [];
        const arcSpacing = style.arcSpacing ?? 0.5;

        
        for (let i = 0; i < levels[1].length; i++) {
            const node = levels[1][i];
            const parentElements = [];
            const level1Color = getRingColor(offer, node.node.uuid, selected);
            const sectionAngle = (node.node.children ? node.node.children.length * leafLevel : 0);
            endAngle = startAngle + sectionAngle;

            const points = getRingPoints(center, inner, outer, startAngle, endAngle, arcSpacing);
            const path = getRingPath(points, inner, outer);
            const centerElement = <path
                data-name={node.node.uuid}
                data-level="1"
                key={`center-${i}`}
                d={path}
                fill={level1Color}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                onClick={handleClick}
                strokeLinejoin="round"
                className="sun-root"
            />;
            parentElements.push(centerElement);
            const reverse = startAngle >= 0 && startAngle < 90;
            const cellId = `${offer.uuid}-${i}`;
            const key = `sunburst.${node.node.uuid}.title`;
            if (selected) {
                const textCenter = textInCell(points, cellId, t(key), style.centerText, reverse);
                parentElements.push(textCenter);
            }

            const leafs: JSX.Element[] = [];

            if (node.node.children) {
                let childStartAngle = startAngle;
                // console.log(node.node.name, childLevelAngle);
                for (let j = 0; j < node.node.children.length; j++) {
                    childStartAngle = startAngle + j * (leafLevel);
                    //console.log(childStartAngle);
                    const childNode = node.node.children[j];
                    const level2Color = getRingColor(offer, childNode.uuid, selected);

                    const inR = outer + childMargin;
                    const outR = outer + levelGroth + childMargin;
                    
                    const childPoints = getRingPoints(center, inR, outR, childStartAngle, childStartAngle + leafLevel, arcSpacing);
                    const childPath = getRingPath(childPoints, inR, outR);
                    const handleClick = (event:React.MouseEvent) => {
                        event.stopPropagation();
                        handleClickLeaf(childNode);
                    };
                    const leafElement = <path
                        id={childNode.uuid}
                        data-name={childNode.uuid}
                        data-level="2"
                        key={i + "-" + j}
                        d={childPath}
                        fill={level2Color} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinejoin="round" onClick={handleClick} className="sun-leaf" />;
                    const childSComponents: JSX.Element[] = [];

                    const leafUuid = `leaf-${offer.uuid}-${node.node.uuid}-${childNode.uuid}`;

                    const cellId = `${offer.uuid}-${i}-${j}`;
                    const reverse = childStartAngle > -10 && childStartAngle < 180;
                    const key = `sunburst.${node.node.uuid}.children.${childNode.uuid}.title`;
                    childSComponents.push(leafElement);
                    if (selected) {
                        childSComponents.push(textInCell(childPoints, cellId, t(key), style.leaftText, reverse));
                    }
                    leafs.push(<g key={leafUuid} id={leafUuid}>{childSComponents}</g>);
                }
            }
            startAngle += sectionAngle;
            groups.push(<g key={node.node.uuid} id={node.node.uuid}>
                {parentElements}
                {leafs}
            </g>);
        }
        const handleclick = (event:React.MouseEvent) => {            
            event.stopPropagation();
            appStore.addPopup({
                type: PopupTypes.OFFER,
                uuid: offer.uuid,
                offer: offer,
            });
            // navigate(`/offer/${offer.uuid}`);
        };
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg"
                    key={`svg-${offer.uuid}`}
                    className="sun"
                    width={size}
                    height={size}
                    overflow={"visible"}
                    viewBox={`${-size / 2} ${-size / 2} ${size} ${size}`}
                    onClick={handleClick}
                >
                    <circle key={"circle"} cx={center.x} cy={center.y} r={(inner + outer + childMargin)} fill="#FFFFFF" onClick={handleClick} />
                    {groups}
                </svg>
                <div className={["offer-buton", offer.uuid].join(" ")} onClick={handleclick}></div>
            </>

        );
    };

    useEffect(() => {
        let item: Draggable | undefined = undefined;
        if (!selected ) {
            item = Draggable.create(`.sunburst.${offer.uuid}`, { type: "x,y", edgeResistance: 0.65, inertia: true })[0];
            item.addEventListener("dragstart", () => {
                if (item?.target) {
                    gsap.set(item.target, { filter: "blur(0)" });
                }
            });
            item.addEventListener("dragend", () => {
                if (item?.target) {
                    gsap.to(item?.target, {
                        x: item.x + 10 * item.deltaX,
                        y: item.y + 10 * item.deltaY,
                        duration: 0.3,
                        zIndex: 505,
                        onComplete: () => {
                            selectOffer();
                        }
                    });
                }
            });
        }
        return () => {
            if (item) {
                item.disable();
            }
        };
    }, [selected]);


    return (
        <div
            id={offer.uuid}
            key={offer.uuid}
            className={["sunburst", offer.uuid].join(" ")}
            ref={sunBurstContext}
            style={divStyle}
        >
            {buildSVG()}
        </div>
    );
});

export default Sunburst;
import React, { ReactNode } from "react";
import { PopupProps, PopupTypes } from "../../store/AppStore";
import PopupCriteriaContent from "./content/PopupCriteriaContent";
import PopupOfferContent from "./content/PopupOfferContent";
import PopupServiceContent from "./content/PopupServiceContent";

export interface PopupManagerProps {
    children?: ReactNode,
}

export default class PopupFactory {

    static buildServicePop(popup: PopupProps, onLoad:()=>void): React.ReactElement {
        return <PopupServiceContent popup={popup} onLoad={onLoad}></PopupServiceContent>;
    }

    static buildOfferPop(popup: PopupProps, onLoad:()=>void): React.ReactElement {
        return <PopupOfferContent popup={popup} onLoad={onLoad}></PopupOfferContent>;
    }

    static buildCriteriaPop(popup: PopupProps, onLoad:()=>void): React.ReactElement {
        return <PopupCriteriaContent popup={popup} onLoad={onLoad}></PopupCriteriaContent>;
    }

    // popRef: React.RefObject<AnimateElementHandle>
    static build(popup: PopupProps, onLoad:()=>void): React.ReactElement {

        const factory:Record<string, (popup:PopupProps, onLoad:()=>void)=>React.ReactElement> = {
            [PopupTypes.CRITERIA]: PopupFactory.buildCriteriaPop,
            [PopupTypes.SERVICE]: PopupFactory.buildServicePop,
            [PopupTypes.OFFER]: PopupFactory.buildOfferPop,
        };

        return factory[popup.type](popup, onLoad);
    }
}
import React, { useLayoutEffect, useRef, useState } from "react";
import { MovieClipProps } from ".";
import { gsap } from "gsap";
import ToggleClip from "./ToggleClip";
import resolutionManager from "../../tools/ResolutionManager";
import { range } from "../../tools/array";
import { Dictionary } from "../../tools/types";

import "./_movieclips.scss";


interface BrickGroupProps extends MovieClipProps {
    numBrick:number,
    onClick?:()=>void,
    useWall?:boolean,
    animate?:boolean,
    delays?:Dictionary,
}

/*
const BrickGroup = React.forwardRef<AnimateElementHandle, BrickGroupProps>(function BrickGroup(
    {useWall, numBrick, state, style, children, onClick, className, animate, delays}:BrickGroupProps, ref: React.Ref<unknown>): React.ReactElement {
*/

const BrickGroup = function BrickGroup(
    {useWall, numBrick, state, style, children, onClick, className, animate, delays}:BrickGroupProps): React.ReactElement {
    const mainRef = useRef(null);

    const [numBrickShow, setNumBrickShow] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useLayoutEffect(()=>{
                
        gsap.context(() => {

            if (!animate) {
                for (let i = 1; i <= 4; i++) {                    
                    const index = i-1;
                    if (i <= numBrick) {
                        gsap.set(`#brick-${index}`, {visibility:"visible"});
                    }
                    else {
                        gsap.set(`#brick-${index}`, {visibility:"hidden"});
                    }
                }
                return;
            }

            const diff = numBrick-numBrickShow;
            let brickToShow:number[] = [];
            let brickToRemove:number[] = [];
            if (diff > 0) {
                brickToShow = range(numBrickShow+1, numBrickShow+diff);
            }
            if (diff < 0) {
                // 2 -> 1 -> -1
                brickToRemove = range(numBrickShow+1+diff, numBrickShow);
            }
            let globalDelay = 0;
            if (!isOpen)  {
                globalDelay = delays?.["bricks"] ?? 0; 
            }

            for (let i = 1; i <= 4; i++) {                    
                const index = i-1;
                if (i <= numBrick) {
                    // 1 -> 3 -> diff 2 2,3
                    if (brickToShow.includes(i)) {
                        const delay = brickToShow.length === 1 ? 0:i/20;
                        gsap.set(`#brick-${index}`, {opacity:0, y:"-50px"});
                        gsap.to(`#brick-${index}`, {visibility:"visible", opacity:1, y:"", delay: delay + globalDelay, duration:0.3});
                    } 
                    else {
                        gsap.to(`#brick-${index}`, {visibility:"visible"});
                    }
                    
                    //gsap.to(`#brick-${index}`, {visibility:"visible"});
                }
                else {
                    if ((brickToRemove.includes(i))) {
                        const delay = brickToShow.length === 1 ? 0:(brickToRemove.length-i)/20;
                        gsap.to(`#brick-${index}`, {opacity:0, y:"-50px", delay: delay + globalDelay, duration:0.4});
                    }
                    else {
                        gsap.to(`#brick-${index}`, {visibility:"hidden"});
                    }
                }
            }      

        }, mainRef);
    
        setNumBrickShow(numBrick);
        setIsOpen(true);
    }, [numBrick]);


    const bricks = [];
    const max = 4;
    for (let i = 0; i < max; i++) {
        //const x = 0; //(numBrick-i-1) * 80;
        //const y = (numBrick-i-1) * 36;
        //bricks.push(<ToggleClip key={i} style={{left:x, top:y, zIndex:i}} state={state} className="brick"></ToggleClip>);
        const m = i === 0 ? 0: -53;
        bricks.push(<ToggleClip 
            key={i} 
            style={{marginTop: m, zIndex:(max-i), visibility:"hidden"}} 
            state={state} 
            id={`brick-${4-i-1}`}
            className="brick">    
        </ToggleClip>);
    }
    
    const scaledSytyle = resolutionManager.scaleStyle(style);
    const classNames = ["brick-block", className].join(" ");
    return (
        <div ref={mainRef} className={classNames} style={scaledSytyle} onClick={onClick}>
            { useWall ? <div className="wall"></div>:<></> }
            { bricks }
            { children }
        </div>
    );
};

export default BrickGroup;
import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import { styleManipulator } from "./styles";
import { Dictionary } from "./types";

export interface Size {
    width: number,
    height: number,
}

export interface WindowMetrics {
    ratioW: number,
    ratioH: number,
    innnerSize: Size,
    outerSize: Size, 
}

class ResolutionManager {

    static _instance_:ResolutionManager|undefined;
    public windowMetrics:WindowMetrics;
    public initialSize:Size;

    constructor() {

        this.initialSize = { 
            width: styleManipulator.getStyleVariableAsNum("--original-width"), 
            height: styleManipulator.getStyleVariableAsNum("--original-height"), 
        };

        this.windowMetrics = {
            ratioW: 1,
            ratioH: 1,
            innnerSize: {width: this.initialSize.width, height: this.initialSize.height},
            outerSize: {width: this.initialSize.width, height: this.initialSize.height},
        };

        makeObservable(this, {
            windowMetrics: observable,
        } as AnnotationsMap<this, string>);

        this.updateWindowMetrics();        

        window.addEventListener("resize", () => {
            this.handleResize();
        });
    }

    public normalize(value:string):number {
        return parseFloat(value.replace("px", ""));
    }

    public scaleW(value:number|string) {
        const normalized = typeof value === "string" ? this.normalize(value):value;
        return this.windowMetrics.ratioW * normalized;
    }

    public scaleH(value:number|string) {
        const normalized = typeof value === "string" ? this.normalize(value):value;
        return this.windowMetrics.ratioH * normalized;
    }

    public scaleStyle(style:React.CSSProperties|undefined):Dictionary|undefined {

        if (!style) return;

        const result:Dictionary = {};
        
        const wScalables = ["left", "right", "marginLeft", "marginRight", "width"];
        const hScalables = ["top", "bottom", "marginTop", "marginBottom", "height"];
        for (const key in style) {    
            const styleKey = key as keyof React.CSSProperties;         
            const value = style[styleKey] as string|number;                       
            if (wScalables.includes(key)) result[key] = this.scaleW(value);
            else if (hScalables.includes(key)) result[key] = this.scaleH(value);
            else result[key] = value;
        }   
        return result;
    }

    updateWindowMetrics = ():void => {

        const innnerSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        const outerSize = {
            width: window.outerWidth,
            height: window.outerHeight,
        };

        let width = innnerSize.width;
        let height = innnerSize.height;

        const ratio = innnerSize.width / innnerSize.height;
        if (ratio !== 16/9) {
            const newW = height * 16/9;
            const newH = width * 9/16;
            if (newH > height) {
                width = newW;                                
            } else {
                height = newH;
            }
        }

        const ratioW = width / this.initialSize.width;
        const ratioH = height / this.initialSize.height;
        
        runInAction(()=> {
            this.windowMetrics =  {
                innnerSize, 
                outerSize,
                ratioW: ratioW,
                ratioH: ratioH,
            };    
            styleManipulator.setStyleVariable('--coef-w', this.windowMetrics.ratioW.toFixed(3));
            styleManipulator.setStyleVariable('--coef-h', this.windowMetrics.ratioH.toFixed(3));
        });
    };    

    handleResize = ():void => {
        this.updateWindowMetrics();
    };    

    static getInstance():ResolutionManager {
        if (!ResolutionManager._instance_) {
            ResolutionManager._instance_ = new ResolutionManager();
        }
        return ResolutionManager._instance_;
    }
}

const resolutionManager = ResolutionManager.getInstance();
export default resolutionManager;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import Sunburst, { SunburstStyle } from "../components/circles/SunBurst";
import { useAppStore } from "../store/AppProvider";
import Offer from "../store/Offer";
import resolutionManager from "../tools/ResolutionManager";
import Switch from "../components/circles/Switch";

import "./_CircleOffer.scss";
import { gsap } from "gsap";
import OfferNav from "../components/circles/OfferNav";

const CircleOffer = observer(function Home(): React.ReactElement {
    const appStore = useAppStore();
    const main = useRef(null);
    const [list, setList] = useState<string[]>([]);
    const [selectedOffer, setSelectedOffer] = useState<string>("");

    useEffect(()=>{
        const list = appStore.getFilteredOfferList();
        setList(list);
        if (list.includes(appStore.selectedOffer.uuid)) {
            setSelectedOffer(appStore.selectedOffer.uuid);
        }
        else {
            setSelectedOffer(list[0]);
        }
    }, [appStore.selectedOffer]);


    const sunBurstInitalDelay = 0;

    useLayoutEffect(()=>{
        gsap.context(() => {

            const tl = gsap.timeline();      
            //
            tl.set(".offer-nav", {opacity:0});
            tl.set(".switch", {opacity:0, y:"+50px"});
            //tl.set(".sunburst-container", {opacity:0});
            tl.to(".offer-nav", {opacity: 1}, 0.5);
            tl.to(".switch", {opacity: 1, y:""}, 0.6);


        }, main);
        //
    }, []);

    const innerRadius = Math.round(resolutionManager.scaleW(280));
    const sW = resolutionManager.scaleW(15);
    const style:SunburstStyle = {
        innerRadius, 
        outerRadius: innerRadius * 2,
        center: {x:0, y:0},
        arcSpacing: 0.0, // 0.5
        strokeWidth: sW, // 10
        centerText: {
            reverse: false,
            fontSize: resolutionManager.scaleW(46),
            fontColor: "rgba(255,255,255,1.0)",
            fontFace: "text-base",
        },
        leaftText: {
            reverse: false,
            fontSize: resolutionManager.scaleW(31),
            fontColor: "rgba(255,255,255,1.0)",
            fontFace: "text-base",
        }        
    };
    
    let dx = 0.1;
    const sunbursts = list.map((key, idx) => {
        const offer = appStore.offers[key];
        const selected = (offer.uuid === selectedOffer) ? true:false;
        const divStyle:React.CSSProperties = {
            zIndex: idx + 20,
        };

        const delay = selected ? sunBurstInitalDelay: sunBurstInitalDelay+dx;
        dx+=0.05;
        return (
            <Sunburst 
                key={`offer-${offer.uuid}`} 
                offer={offer} 
                selected={selected}
                style={style} 
                tree={appStore.serviceTree}
                divStyle={divStyle}
                delays={{open:delay}}>                
            </Sunburst>
        );
    });

    const handleChangeOffer = (offer:Offer):void => {
        appStore.setSelectedOffer(offer);
    };


    const handleChangeSwicth = (selected:boolean) => {
        appStore.selectTypeAircraft(selected);
        const list = appStore.getFilteredOfferList();
        setList(list);      
        if (list.includes(appStore.selectedOffer.uuid)) {
            setSelectedOffer(appStore.selectedOffer.uuid);
        }
        else {
            setSelectedOffer(list[0]);
        }          
    };

    return (
        <div className="circle-offers" ref={main}>
            <div className="sunburst-container">
                { sunbursts }
            </div>
            { appStore.selectedOffer ?  <OfferNav selectedOffer={appStore.offers[selectedOffer]} onChange={handleChangeOffer} showBar={true} orderedOffers={list}></OfferNav>:<></> }
            <Switch selected={appStore.linefit} onChange={handleChangeSwicth}></Switch>
        </div>
    );
});

export default CircleOffer;
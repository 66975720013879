import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Service } from "../../../store/Offer";
import { useAppStore } from "../../../store/AppProvider";
import { useTranslation } from "react-i18next";
import ImageGrid from "./ImageGrid";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../_Popup.scss";
import { PopupContentProps } from "../Popup";



export default observer(function PopupServiceContent({popup, onLoad}:PopupContentProps): React.ReactElement {
    const appStore = useAppStore();
    const { t } = useTranslation("embed");
    const [pages, setPages] = useState<Service[]>([]);

    const uuid = popup.uuid;
    const offer = popup.offer;

    if (!offer) return <></>;

    useEffect(()=> {
        const loadPopup = async()=> {
            const popup = await appStore.loadPopup(uuid);
            if (popup.offers?.[offer.uuid]) {
                setPages(popup.offers[offer.uuid]);
            }
            else {
                setPages(popup.pages);
            }             
            if (onLoad) {
                onLoad();
            }   
        };
        loadPopup();
    }, [uuid]);

    if (pages.length === 0) {
        return <></>;
    }
    
    const Slider = () => {
        const items = pages.map(page=>{
            // console.log("render ", page);
            return <SwiperSlide key={page.uuid}>
                <h1 className="title">{ t(`pages.${page.uuid}.title`) }</h1>
                { page.grid && page.grid.items.length !== 0 ? <ImageGrid service={page}></ImageGrid>:<></>}            
                <article>
                    <h1>{ t(`pages.${page.uuid}.sub_title`) }</h1>
                    <p>{ t(`pages.${page.uuid}.description`) }</p>
                </article>            
            </SwiperSlide>;
        });
        return (
            <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                { items }
            </Swiper>            
        );
    };

    return (
        <div className="popup-service">
            <Slider></Slider>
        </div>
    );
});
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../_Popup.scss";
import { PopupContentProps } from "../Popup";
import { useAppStore } from "../../../store/AppProvider";



export default observer(function PopupOfferContent({popup, onLoad}:PopupContentProps): React.ReactElement {
    const { t } = useTranslation("embed");
    const appStore = useAppStore();
    const offer = popup.offer;

    if (!offer) return <></>;

    const title = t(`offers.${offer.uuid}.popup.title`);
    const subTitle = t(`offers.${offer.uuid}.popup.sub_title`);
    const desription = t(`offers.${offer.uuid}.popup.desc`);
    const navigate = useNavigate();
    const location = useLocation();

    const handleDiscover = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.setSelectedOffer(offer);
        navigate(`/offer/${offer.uuid}?origin=${location.pathname}`);
    };  

    useLayoutEffect(()=>{
        if (onLoad) {
            onLoad();
        }
    }, []);
    const icon = <div className={`offer-buton ${popup.offer?.uuid}`}></div>;
    return (
        <div className="popup-offer">
            <section>
                <div className="content">
                    <h1 className="title prevent-select" onClick={handleDiscover}>{ icon } {title}</h1>
                    <article>
                        <h1>{subTitle}</h1>
                        <p>{desription}</p>
                    </article>
                </div>
                <div className="offer-image">
                    <img src={`/images/offer_mosaic_${offer.uuid}.png`} className="prevent-select"></img>
                </div>
            </section>
            <button className="btn-primary" onClick={handleDiscover}>{t("common.buttons.discover")}</button>
        </div>
    );
});
import React, { useLayoutEffect, useRef, useState } from "react";
import { useAppStore } from "../../store/AppProvider";
import ToggleClip, { ToggleState } from "../movieclip/ToggleClip";
import { gsap } from "gsap";
import { observer } from "mobx-react-lite";
import { ZoneProps } from ".";
import './_AircraftSelector.scss';

export default observer(function AircraftSelector({offer, onClickMap}:ZoneProps): React.ReactElement {
    const appStore = useAppStore();

    const [cabinOpen, setCabinOpen] = useState(false);

    const lru = appStore.getMappingPopup("offer.aircraft.server", offer);
    const retail_services =  appStore.getMappingPopup("offer.aircraft.crew", offer);
    const cabin_services =  appStore.getMappingPopup("offer.aircraft.pax", offer);
    const antenna = appStore.getMappingPopup("offer.aircraft.antenna", offer);
    const showCabin = retail_services || cabin_services;
    const selected = useRef<Record<string, string>>({});

    const stopAllFocus = (duration:number = 0) => {
        for (const k in selected.current) {
            stopFocus(k, selected.current[k], duration);
        }
    };

    const stopFocus = (popup: string, focus:string, duration:number = 0) => {
        if (selected.current[popup]) { 
            gsap.killTweensOf(focus);
            gsap.to(focus, { opacity: 0, duration: duration, onComplete: () =>{
                delete selected.current[popup];
            } });                    
        }
    };

    const startFocus = (popup: string, focus:string) => {
        if (!selected.current[popup]) {
            gsap.set(focus, { visibility: "visible", opacity: 0, scale:1.0 });
            gsap.to(focus, { opacity: 1, duration: 0.3, zIndex: 1 });
            gsap.to(focus, { scale: 1.1, repeat:-1, yoyo:true, duration: 0.3 });    
        }
        selected.current[popup] = focus;
    };

    useLayoutEffect(() => {
        //
        if (appStore.popupList.length) {
            // focus toujours actif ?
            for (const k in selected.current) {
                if (!appStore.isPopupOpen(k)) {
                    stopFocus(k, selected.current[k], 0);
                }
            }
        }
        else {            
            stopAllFocus();
        }
    }, [appStore.popupList]);

    const handleClickMap = (popup:string, newFocus:string|null = null) => {

        const isCabinAction = popup === "cabin";

        if (newFocus) {
            startFocus(popup, newFocus);
        }

        if (onClickMap && !isCabinAction) {
            onClickMap(popup, "aircraft");
        }
        
    };

    const handleOpenCabin = (event:React.MouseEvent) => {
        event.stopPropagation();
        stopAllFocus();
        if (lru) {
            appStore.removePopup(lru);
        }
        setCabinOpen(true);
    };

    const handleCloseCabin = () => {
        if (cabinOpen) {
            setCabinOpen(false);
        }
    };

    useLayoutEffect(()=>{
        if (cabinOpen) {
            gsap.set(".cabin", {opacity:0, scale:0.5});
            gsap.to(".cabin", {opacity:1, scale:1, duration:0.2});    
        }
        else {
            gsap.to(".cabin", {opacity:0, scale:0.5, duration:0.2});    
        }

    }, [cabinOpen]);

    const handleClickCrew = (event:React.MouseEvent) => {
        event.stopPropagation();
        if (retail_services) {
            handleClickMap(retail_services, ".aircraft-cabin-focus.crew");
        }
    };

    const handleClickPax = (event:React.MouseEvent) => {
        event.stopPropagation();
        if (cabin_services) {
            handleClickMap(cabin_services, ".aircraft-cabin-focus.pax");
        }
    };

    const handleClickServer = (event:React.MouseEvent) => {
        event.stopPropagation();
        if (lru) {
            handleClickMap(lru, ".aircraft-server-focus");
        }
    };

    const handleClickAntenna = (event:React.MouseEvent) => {
        event.stopPropagation();
        if (antenna) {
            handleClickMap(antenna, ".aircraft-antenna .zone-focus-1");
        }
    };    

    //debugClip(".aircraft-antenna");

    return (
        <div className="aircraft-selector" onClick={handleCloseCabin}>
            <div className="aircraft-shadow-2"></div>
            <div className="aircraft"></div>
            <div className="aircraft-cabin-focus crew"></div>
            <div className="aircraft-cabin-focus pax"></div>

            { showCabin ? <div className="aircraft-cabin" onClick={handleOpenCabin}></div>:<></> }

            { lru ? <div className="aircraft-server" onClick={handleClickServer}></div>:<></>}
            <div className="aircraft-server-focus"></div>

            <ToggleClip className="aircraft-antenna" state={antenna ? ToggleState.selected:ToggleState.unselected} onClick={handleClickAntenna}>
                <div className="zone-focus-1"></div>
            </ToggleClip>


            <div className="cabin">
                <ToggleClip className="cabin-crew" state={retail_services ? ToggleState.selected:ToggleState.unselected} onClick={handleClickCrew}>
                </ToggleClip>
                <ToggleClip className="cabin-pax" state={cabin_services ? ToggleState.selected:ToggleState.unselected} onClick={handleClickPax}>
                </ToggleClip>
            </div>

        </div>
    );
});

import React, { CSSProperties, useLayoutEffect } from "react";
import "./_PodiumNav.scss";
import Offer from "../../store/Offer";
import { gsap } from "gsap";
import resolutionManager from "../../tools/ResolutionManager";

interface PoidumButtonProps {
    offer: Offer,
    position: number,
    className:string, 
    onClick?: React.MouseEventHandler,
}

const PoidumButton = function PoidumButton({offer, position, className, onClick}:PoidumButtonProps): React.ReactElement {
    const podiumVisible = position <= 2;
    
    const podiumY = [-200, -140, -70, 0, 0, 0, 0, 0];
    const podiumButtonY = [0, 30, 60, 70, 70, 70, 70, 70];

    const barId = `bar-${offer.uuid}`;
    const buttonId = `button-${offer.uuid}`;

    useLayoutEffect(()=> {
        gsap.set(`#${barId}`, {y:0, opacity:0});
    }, []);

    useLayoutEffect(()=> {
        //
        gsap.set(`#${buttonId}`, {y:`${resolutionManager.scaleH(podiumButtonY[position])}px`});
        const y = resolutionManager.scaleH(podiumY[position]);
        gsap.set(`#${barId}`, {y:0});
        gsap.to(`#${barId}`, {y:`${y}px`, opacity:1});
    }, [offer, position]);

    const style: CSSProperties = {
        zIndex: position+5,       
    };
    return (
        <div id={buttonId} className="podium-button" style={style}>
            <div id={barId} className="bar">
                { podiumVisible ? <div className="podium"></div>:<></> }
                <button className={["offer-buton", offer.uuid, className].join(" ")} onClick={onClick}></button>
            </div>
            { podiumVisible ? <div className="podium-mask"></div>:<></> }            
        </div>
    );
};

export default PoidumButton;

import React, { useEffect, useState } from "react";
import i18next from "../../app/initI18n";
import resolutionManager from "../../tools/ResolutionManager";
import FPSCounter from "./FPSCounter";
import lastCommit from "../../last-commit";

import "./_DebugPanel.scss";

const DebugPanel = function DebugPanel(): React.ReactElement {
    
    const [messages, setMessages] = useState<string[]>([]);
    const [show, setShow] = useState(false);

    const update = () => {
        const lines = [];
        lines.push(`inner: ${window.innerWidth}x${window.innerHeight}`);
        lines.push(`outter: ${window.outerWidth}x${window.outerHeight}`);
        lines.push(`rw: ${resolutionManager.windowMetrics.ratioW.toFixed(2)} rh: ${resolutionManager.windowMetrics.ratioH.toFixed(2)}`);
        lines.push(`i18n: ${i18next.language} com:${lastCommit.slice(0, 8)}`);
        setMessages(lines);        
    };

    useEffect(() => {
        window.addEventListener("resize", () => update());
        update();
    }, []);
    
    const handleClick = () => {
        setShow(!show);
    };

    return (
        <div className="debug-panel" onClick={handleClick} style={{opacity:show ? 1:0}}>
            <ul>
                {messages.map((e, idx) => <li key={idx}>{e}</li>)}
            </ul>
            <FPSCounter refreshRate={1000/60}></FPSCounter>
        </div>
    );
};

export default DebugPanel;
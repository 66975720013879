import { observer } from "mobx-react-lite";
import { useAppStore } from "../../../store/AppProvider";
import { useTranslation } from "react-i18next";
import BrickSelector from "./BrickSelector";
import React, { useEffect } from "react";

import { PopupContentProps } from "../Popup";
import "../_Popup.scss";


export default observer(function PopupCriteriaContent({popup, onLoad}:PopupContentProps): React.ReactElement {
    const appStore = useAppStore();
    const { t } = useTranslation("embed");

    const criteria = appStore.getCriteriaByUuid(popup.uuid);

    const handleSelectBrick = (num:number) => {
        if (criteria) {
            appStore.changeCriteriaValue(criteria, num);
        }
    };    

    const handleClose = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.removePopup(popup.uuid);
    };

    const handlePopupContentClick = (event:React.MouseEvent) => {
        event.stopPropagation();
    };

    useEffect(()=>{
        if (onLoad) onLoad();
    }, []);

    if (!criteria) return <></>;

    const value = appStore.getCriteriaValue(criteria);
    const title = t(`criteria.${criteria.uuid}.popup.title`);
    const subTitle = value !== -1 ? t(`criteria.${criteria.uuid}.value_${value}.title`):"";
    // const desription = value !== -1 ? t(`criteria.${criteria.uuid}.value_${value}.desc`):"";
    return (
        <div className="popup-criteria" onClick={handlePopupContentClick}>
            <h1 className="title">{title}</h1>
            <BrickSelector onSelectBrick={handleSelectBrick} selected={value}></BrickSelector>
            <article>
                <h1>{subTitle}</h1>
            </article>
            <button className="btn-primary" onClick={handleClose}>{t("common.buttons.continue")}</button>
        </div>
    );
});
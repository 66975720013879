import { Dictionary } from "./types";

export interface SimpleNode {
    uuid: string,
    popup?: string,
    parent?:SimpleNode,
    children?: SimpleNode[],
    data?: Dictionary,    
}

export function addParents(root:SimpleNode): SimpleNode {
    const visited:Record<string, boolean> = {};
    const stack:SimpleNode[] = [root];
    while(stack.length !== 0) {
        const n = stack.pop();
        if (n && !visited[n.uuid]) {
            visited[n.uuid] = true;            
            if (n.children) {
                for (let j = 0; j < n.children.length; j++) {
                    const c = n.children[j];
                    c.parent = n;
                    stack.unshift(c);
                }    
            }
        }
    }
    return root;
}

export function findServiceInTree(root:SimpleNode, serviceUuid:string):SimpleNode|undefined {
    const visited:Record<string, boolean> = {};
    const stack:SimpleNode[] = [root];
    while(stack.length !== 0) {
        const n = stack.pop();
        if (n && !visited[n.uuid]) {
            visited[n.uuid] = true;            
            if (n.uuid === serviceUuid) return n;
            if (n.children) {
                for (let j = 0; j < n.children.length; j++) {
                    const c = n.children[j];
                    if (c.uuid === serviceUuid) return c;
                    stack.unshift(c);
                }    
            }
        }
    }
    return;
}

export function buildLevels(node:SimpleNode):{node:SimpleNode, level:number}[][] {
    const visited:Record<string, boolean> = {};
    const stack = [{node:node, level:0}];
    const levels:{node:SimpleNode, level:number}[][] = [];
    while(stack.length !== 0) {
        const n = stack.pop();
        if (n && !visited[n.node.uuid]) {
            visited[n.node.uuid] = true;
            if (!levels[n.level]) {
                levels[n.level] = [];
            }
            levels[n.level].push(n);
            if (n.node.children) {
                for (let j = 0; j < n.node.children.length; j++) {
                    const c = n.node.children[j];
                    stack.unshift({node:c, level:n.level+1});
                }    
            }
        }
    }
    return levels;
}

export const DFS = (node:SimpleNode):void => {
    const visited:Record<string, boolean> = {};
    const stack = [node];
    while(stack.length !== 0) {
        const n = stack.pop();
        if (n && visited[n.uuid] === false) {
            visited[n.uuid] = true;
            if (n.children) {
                for (let j = 0; j < n.children.length; j++) {
                    const c = n.children[j];
                    stack.push(c);
                }    
            }
        }
    }
};


import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "../../store/AppProvider";
import { useLocation } from "react-router-dom";
import { PopupProps, PopupTypes } from "../../store/AppStore";
import { gsap } from "gsap";
import Popup from "./Popup";

import "./_Popup.scss";
import resolutionManager from "../../tools/ResolutionManager";
export interface PopupManagerProps {
    children?: ReactNode,
}

const PopupManager = observer(function PopupManager({ children }: PopupManagerProps): React.ReactElement {

    const appStore = useAppStore();
    const location = useLocation();
    const [list, setList] = useState<PopupProps[]>([]);    

    useEffect(() => {
        if (list.length) {
            closePopups(list, 0.2);
        }
    }, [location]);

    const maxPopup = 5;

    const getPopupToClose = () => {
        const len = appStore.popupList.length;        
        if (len && len > maxPopup) return [appStore.popupList[0]];

        const toClose = list.filter((p)=> {
            if (!appStore.isPopupOpen(p.uuid)) return true;
            return false;
        });
        if (toClose.length) return toClose;

        return [];
    };

    const closePopups = (popups:PopupProps[], closeDuration:number=0.3) => {
        popups.forEach((p, idx) => {
            //
            const isLast = idx === popups.length-1;
            const handleComplete = () => {
                //console.log(`remove ${p.uuid}`, appStore.popupList.length);
                if (appStore.isPopupOpen(p.uuid)) appStore.removePopup(p.uuid);
                if (isLast) {
                    setList([...appStore.popupList]);
                }
            };                    
            const elmt = document.querySelector(`.popup.${p?.uuid}`);
            if (elmt) {
                const rect = elmt.getBoundingClientRect();
                const targetY = rect.y > resolutionManager.windowMetrics.innnerSize.height / 2 ? "+600px":"-1800px";
                gsap.to(`.popup.${p?.uuid}`, {scale:0.5, y:targetY, duration:closeDuration, onComplete: handleComplete});                    
            }
        });
    };


    useLayoutEffect(()=>{

        const len = appStore.popupList.length;
        if (len) {
            const popupClosing = getPopupToClose();
            if (popupClosing.length) {
                closePopups(popupClosing);
            }    
            else {
                setList([...appStore.popupList]);
            }
        } else if (list.length) {
            closePopups(list);
        }
    }, [appStore.popupList]);    

    const items = list.map(p => {
        const useFavorite = p.type !== PopupTypes.CRITERIA;
        return <Popup popup={p}  key={p.uuid} useFavorite={useFavorite}></Popup>;
    });

    return (
        <div className="popup-manager">
            { items }
            {children}
        </div>
    );
});

export default PopupManager;
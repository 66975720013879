import { observer } from "mobx-react-lite";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import ScrollDemo from "./pages/ScrollDemo";
import { useAppStore } from "./store/AppProvider";
import CircleOffer from "./pages/CircleOffer";
import PopupManager from "./components/popup/PopupManager";
import DebugPanel from "./components/debug/DebugPanel";
import AnimationPlayGround from "./pages/AnimationPlayGround";
import Configurator from "./pages/Configurator";
import Offer from "./pages/Offer";
import FavoritePanel from "./components/favorites/FavoritePanel";

function getRoutes() {
    return (<Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/scroll" element={<ScrollDemo></ScrollDemo>} />
        <Route path="/circles" element={<CircleOffer></CircleOffer>} />
        <Route path="/animations" element={<AnimationPlayGround></AnimationPlayGround>} />
        <Route path="/configurator" element={<Configurator></Configurator>} />
        <Route path="/offer/:uuid" element={<Offer></Offer>} />

    </Routes>);
}

const Router = observer(function Router(): React.ReactElement<
    unknown,
    string | React.JSXElementConstructor<unknown>
> | null {
    const appStore = useAppStore();
    const location = useLocation();
    const navigate = useNavigate();

    if (!appStore.loaded) return (
        <>
            <div>Loading...</div>
        </>
    );

    const handleClickMain = (event:React.MouseEvent<HTMLDivElement>):void => {
        event.stopPropagation();
        console.log(">>>>>>>>>>>>>>>>>>>>click main");
        if (appStore.popupList.length) {
            appStore.closeAllPopups();
        }
        if (location.hash === "#bookmarks") {
            navigate(location.pathname);
        }
    };

    const routes = appStore.loaded ? getRoutes():<div>Loading...</div>;
    return (
        <>
            <FavoritePanel></FavoritePanel>
            <div className="main" onClick={handleClickMain}>
                <Header></Header>
                <DebugPanel></DebugPanel>            
                <PopupManager></PopupManager>
                { routes }
            </div>
        </>
    );
});

export default Router;
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import "./_FavoritePanel.scss";
import { gsap } from "gsap";
import resolutionManager from "../../tools/ResolutionManager";
import { useAppStore } from "../../store/AppProvider";
import { observer } from "mobx-react-lite";
import { PopupProps } from "../../store/AppStore";


interface PopupReduceProps {
    popup: PopupProps;
}


function PopupReduce({popup}:PopupReduceProps):React.ReactElement {
    
    const { t } = useTranslation("embed");
    const appStore = useAppStore();
    const [title, setTitle] = useState("");

    const handleOpenPopup = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.addPopup(popup);
    };

    const handleRemoveFav = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.removeFromFavorite(popup);
    };   

    const uuid = popup.uuid;

    useEffect(()=>{

        const loadServicePopup = async()=>{
            const pop = await appStore.loadPopup(popup.uuid);
            const newTitle = t(`pages.${pop.pages[0].uuid}.title`);
            setTitle(newTitle);        
        };
        switch(popup.type) {
        case "service":
            // t(`pages.${page.uuid}.title`)
            loadServicePopup();        
            break;
        case "offer":
            setTitle(t(`offers.${popup.offer?.uuid}.popup.title`));
            break;    
        }    

    }, [popup]);

    const icon = popup.type === "offer" ? <div className={`offer-buton ${popup.offer?.uuid}`}></div>:<></>;
    return (
        <>
            <li className="prevent-select" key={uuid} onClick={handleOpenPopup}>                
                <h1>{ icon } {title}</h1>
                <button className="btn-delete" onClick={handleRemoveFav}></button>
            </li>        
        </>
    );
}


export default observer(function FavoritePanel(): React.ReactElement {
    const location = useLocation();
    const { t } = useTranslation("embed");
    const appStore = useAppStore();
    // const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        setIsOpen(location.hash === "#bookmarks");
    }, [location]);

    useLayoutEffect(()=>{
        
        const w = resolutionManager.scaleW(1271);
        const duration = 0.3;
        if (!isOpen) {
            gsap.to(".main", {x:"0px", duration: duration});
            gsap.to(".bookmarks-panel", {right:`-${w}px`, duration: duration});
            return;
        }
        gsap.set(".bookmarks-panel", {right:`-${w}px`});
        gsap.to(".main", {x:`-${w}px`, duration: duration});
        gsap.to(".bookmarks-panel", {right:"0px", duration: duration, visibility:"visible"});
    }, [isOpen]);

    const items = appStore.favortitePopups.map(popup => {
        return (
            <PopupReduce key={popup.uuid} popup={popup}></PopupReduce>
        );
    });

    const handleReset = (event:React.MouseEvent) => {
        event.stopPropagation();
        appStore.resetFavorites();
    };

    return (
        <div className="bookmarks-panel">
            <h1>{t("bookmarks.title")}</h1>
            <ul>
                { items }
            </ul>
            <button className="btn-reset" onClick={handleReset}>{t("common.buttons.reset").toUpperCase()}</button>
        </div>
    );
});

import React from "react";
import { useTranslation } from "react-i18next";
import { SwitchButtonProps } from "../configurator/AircraftTypeSelector";
import { useAppStore } from "../../store/AppProvider";

export default function Switch({selected, onChange}:SwitchButtonProps): React.ReactElement {
    const { t } = useTranslation("embed");
    const appStore = useAppStore();

    const lineFitClass = appStore.linefit ? "selected" : "";
    const retroFitClass = appStore.linefit ? "" : "selected";

    const handleChange = (event:React.MouseEvent) => {
        event.stopPropagation();
        if (onChange) {
            onChange(!selected);
        }
    };

    return (
        <div className="switch" onClick={handleChange}>
            <h1 className={["switch-left-label", retroFitClass].join(" ")}>{t("criteria.aircraft_type.value_0.title")}</h1>
            <div className={["switch-bg", selected ? "selected":null].join(" ")}>
                <div className="switch-button"></div>
            </div>
            <h1 className={["switch-right-label", lineFitClass].join(" ")}>{t("criteria.aircraft_type.value_1.title")}</h1>
        </div>
    );
}


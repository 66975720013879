
import i18next, { Resource, i18n } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as importTranslations from "../translations/index";

const { REACT_APP_I18NEXT_DEBUG = false } = process.env;

// adding translations with namespace
const embedTranslations = (translations: typeof importTranslations, namespace: string): Resource => {
    const resources:Array<Resource> = [];
    for (const [key, value] of Object.entries(translations)) {
        const namedTranslation = { [key] : {
            [namespace] : value
        }};
        resources.push(namedTranslation);
    }

    return Object.assign({}, ...resources);
};

const languageTypes = {
    "dense": ["ja", "ko", "zh-CN", "zh-HK", "zh-TW", "zh"],
    "tall": ["ar", "bn", "fa", "gu", "hi", "km", "kn", "lo", "ml", "mr", "my", "ne", "pa", "si", "ta", "te", "th", "ur", "vi"]
};

let type: keyof typeof languageTypes;

function getLanguageType(locale: string): string {
    for (type in languageTypes) {
        if (languageTypes[type].includes(locale)) {
            return type;
        }
    }

    return "english-like";
}

export const i18nLanguageType = {...i18next, languageType: getLanguageType(i18next.language)};


export const initI18n = async():Promise<i18n> =>  {
// init i18n with config and feed resources with local translations
    const resources = embedTranslations(importTranslations, "embed");

    await i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources,
            debug: REACT_APP_I18NEXT_DEBUG === "true",
            detection: {
                order: ["querystring", "localStorage", "navigator"],
                caches: ["localStorage"],
            },
            interpolation: {prefix: "{", suffix: "}"}
        });
    return i18next;
};

export default i18next;

export interface Point {
    x: number;
    y: number;
}

export function getRingPoints(center:Point, innerRadius:number, outerRadius:number, startAngle:number, endAngle:number, spacing:number=0):Point[] {
    startAngle += spacing;
    endAngle -= spacing;
    
    const a = {
        x: innerRadius * Math.cos(startAngle * Math.PI / 180) + center.x,
        y: innerRadius * Math.sin(startAngle * Math.PI / 180) + center.y,
    };
    const b = {
        x: outerRadius * Math.cos(startAngle * Math.PI / 180) + center.x,
        y: outerRadius * Math.sin(startAngle * Math.PI / 180) + center.y,
    };
    const c = {
        x: outerRadius * Math.cos(endAngle * Math.PI / 180) + center.x,
        y: outerRadius * Math.sin(endAngle * Math.PI / 180) + center.y,
    };
    const d = {
        x: innerRadius * Math.cos(endAngle * Math.PI / 180) + center.x,
        y: innerRadius * Math.sin(endAngle * Math.PI / 180) + center.y,
    };

    return [a, b, c, d];
}

export function getRingPath(points:Point[], outerRadius:number, innerRadius:number):string {
    const path = `M${points[0].x} ${points[0].y} L${points[1].x} ${points[1].y} A${innerRadius} ${innerRadius} 0 0 1 ${points[2].x} ${points[2].y} L${points[3].x} ${points[3].y} A${outerRadius} ${outerRadius} 0 0 0 ${points[0].x} ${points[0].y} Z`;
    return path;
}


export function getRingMediumSize(points:Point[]):number {
    const [a, b, c, d] = points;
    const a1 = getPolygonCentroid([a, b]);
    const a2 = getPolygonCentroid([c, d]);
    return getDistance(a1, a2);
}

export function getPolygonCentroid(points:Point[]):Point { 
    const centroid = {x: 0, y: 0};
    for(let i = 0; i < points.length; i++) {
        const point = points[i];
        centroid.x += point.x;
        centroid.y += point.y;
    }
    centroid.x /= points.length;
    centroid.y /= points.length;
    return centroid;
} 

export const getDistance = (a:Point, b:Point):number => {
    return Math.sqrt( Math.pow((a.x-b.x), 2) + Math.pow((a.y-b.y), 2) );
};

export const getPointOnSegment = (a:Point, b:Point, pct:number):Point => {
    const dx = (b.x - a.x) * pct;
    const dy = (b.y - a.y) * pct;
    return {x: a.x + dx, y: a.y + dy};
};

// https://stackoverflow.com/questions/6061880/html5-canvas-circle-text
export function drawTextAlongArc(context:CanvasRenderingContext2D, str:string, centerX:number, centerY:number, radius:number, angle:number):void {
    const len = str.length;
    let s:string = "";
    let letterAngle:number;

    context.save();
    context.textAlign = 'center';
    context.translate(centerX, centerY);
    context.rotate(angle + Math.PI / 2);

    for (let n = 0; n < len; n++) {
        s = str[n];
        letterAngle = 0.5*(context.measureText(s).width / radius);

        context.rotate(letterAngle);
        context.save();

        context.translate(0, -radius);
        context.fillText(s, 0, 0);
        context.restore();

        context.rotate(letterAngle);
    }
    context.restore();
}


import React, { CSSProperties } from "react";
import { Service } from "../../../store/Offer";
import { Dictionary } from "../../../tools/types";
import { cssNameToReact } from "../../../tools/styles";
import { observer } from "mobx-react-lite";
import resolutionManager from "../../../tools/ResolutionManager";

import "./_ImageGrid.scss";

export interface ImageGridProps {
    service:Service,
}

const ImageGrid = observer(function ImageGrid({service}:ImageGridProps): React.ReactElement {

    const sizeCell = resolutionManager.scaleW(54);

    const gridSize:CSSProperties = {
        gridTemplateRows: `repeat(${service.grid.rows}, ${sizeCell}px)`,
        gridTemplateColumns: `repeat(${service.grid.columns}, ${sizeCell}px)`,
    };

    const images = service.grid.items.map((i, idx) => {
        const cssStyle:Dictionary = {};
        for (const key in i.style) {
            cssStyle[cssNameToReact(key)] = i.style[key];
        }

        if (i.type === "div") {
            return (
                <div key={idx} className={`grid-item item-${idx}`} style={cssStyle}></div>
            );
        }

        return (
            <figure key={idx} className={`grid-item item-${idx}`} style={cssStyle}>
                <img src={i.src} className={"grid-img"} alt={`${i.src}`}></img>
            </figure>
        );
    });

    return (
        <div className="image-grid" style={gridSize}>
            { images }
        </div>
    );
});

export default ImageGrid;
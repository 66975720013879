import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useAppStore } from "../../store/AppProvider";
import { observer } from "mobx-react-lite";
import Offer from "../../store/Offer";
import { getOfferColor } from "../../tools/styles";
import { useTranslation } from "react-i18next";
import resolutionManager from "../../tools/ResolutionManager";
import { gsap } from "gsap";

import "./_OfferNav.scss";
import { Dictionary } from "../../tools/types";
interface OfferButtonProps {
    offer: Offer,
    onClick?: React.MouseEventHandler
    className?: string,    
}

export const OfferButton = function ({ offer, onClick, className }: OfferButtonProps): React.ReactElement {    
    return (
        <button className={["offer-buton", offer.uuid, className].join(" ")} onClick={onClick}></button>
    );
};


interface OfferBarProps {
    selectedOffer: Offer,
    orderedOffers: string[],
}


const OfferBar = function OfferBar({ selectedOffer, orderedOffers }: OfferBarProps): React.ReactElement {
    const arrowPosition = selectedOffer ? resolutionManager.scaleW(2180) + orderedOffers.indexOf(selectedOffer.uuid) * (resolutionManager.scaleW(245)) : 0;
    const baseColor = getOfferColor(selectedOffer);
    const arrowColor = `transparent transparent ${baseColor} transparent`;
    return (
        <>
            <div className={["triangle", selectedOffer.uuid].join(" ")} style={{ left: arrowPosition, borderColor: arrowColor }}></div>
            <div className={["color-bar", selectedOffer.uuid].join(" ")} style={{ backgroundColor: baseColor }}></div>
        </>
    );
};

export interface OfferNavProps {
    selectedOffer?: Offer,
    orderedOffers: string[],
    onChange?: (offer: Offer) => void,
    showBar?: boolean,
    delays?:Dictionary,    
}

const OfferNav = observer(function OfferNav({ selectedOffer, orderedOffers, showBar, onChange }: OfferNavProps): React.ReactElement {
    const appStore = useAppStore();
    const { t } = useTranslation("embed");
    const mainRef = useRef(null);
    const previousPositions = useRef<Record<string, DOMRect>>({});
    
    const firstOffer = selectedOffer ? selectedOffer : appStore.getOfferAt(0);

    const handleChange = (offer: Offer): void => {
        if (onChange) {
            onChange(offer);
        }
    };

    useEffect(() => {
        orderedOffers.forEach(o => {
            const element = document.querySelector(`.offer-buton.${o}`);
            if (element) {
                const rect = element.getBoundingClientRect();
                previousPositions.current[o] = rect;
            }                    
        });
    }, []);

    useEffect(() => {
        if (!showBar) {
            gsap.set(".offer-nav", { y: "+=100px", opacity: 0 });
            gsap.to(".offer-nav", { y: "0", opacity: 1});
            const uuid = orderedOffers[0];
            gsap.to(`.offer-buton.${uuid}`, {scale:1.2});

        }
    }, [showBar]);

    useLayoutEffect(() => {
        gsap.context(() => {
            for (let i = 0; i < orderedOffers.length; i++) {
                const uuid = orderedOffers[i];
                const element = document.querySelector(`.offer-buton.${uuid}`);
                if (element && previousPositions.current?.[uuid]) {
                    const newRect = element.getBoundingClientRect();
                    const prev = previousPositions.current?.[uuid];
                    const dx = prev.x-newRect.x;
                    if (dx !== 0) {
                        gsap.set(element, {x: `${dx}`, scale:1.0});
                        gsap.to(element, {x: ``, scale: i===0 ? 1.2:1.0, duration:0.3});    
                    }
                    previousPositions.current[uuid] = newRect;    
                }    
            }
        });

    }, [orderedOffers]);


    const buttons = orderedOffers.map((offerUuid, idx) => {
        const offer = appStore.offers[offerUuid];
        const isSelected = selectedOffer && selectedOffer.uuid === offer.uuid;
        const isFirst = idx === 0; 
        const className =  [];
        className.push(isSelected?"selected":"");
        className.push(isFirst?"first":"");

        const handleClick = (event:React.MouseEvent) => {
            event.stopPropagation();
            handleChange(offer);
        };

        return <OfferButton
            className={className.join(" ")}
            offer={offer}
            key={offer.uuid}
            onClick={handleClick}></OfferButton>;
    });

    const title = selectedOffer ? `offers.${selectedOffer.uuid}.title` : "circles.title"; // 
    const bar = showBar ? <OfferBar selectedOffer={firstOffer} orderedOffers={orderedOffers}></OfferBar> : <></>;
    return (
        <div className="offer-nav" ref={mainRef}>
            <div className="offer-nav-buttons prevent-select">
                <div className={["nav-title", selectedOffer ? selectedOffer.uuid : ""].join(" ")}>{t(title).toUpperCase()}</div>
                {buttons}
            </div>
            {bar}
        </div>
    );
});

export default OfferNav;
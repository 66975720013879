import React, { useRef } from "react";
import { Criteria, criteriaNameToUuid } from "../../store/Offer";
import BrickGroup from "../movieclip/BrickGroup";
import ToggleClip from "../movieclip/ToggleClip";
import resolutionManager from "../../tools/ResolutionManager";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../store/AppProvider";
import { observer } from "mobx-react-lite";
import { MovieClipProps } from "../movieclip";
import { Dictionary } from "../../tools/types";

import "../movieclip/_movieclips.scss";
import "./_CriteriaSelector.scss";


export interface CriteriaSelectorProps extends MovieClipProps {
    criteria?: Criteria,
    value: number,
    delays?:Dictionary,
}

//const CriteriaSelector = React.forwardRef<AnimateElementHandle, CriteriaSelectorProps>(function CriteriaSelector({ criteria, value, state, className, style, children, delays }: CriteriaSelectorProps, ref: React.Ref<unknown>): React.ReactElement {

const CriteriaSelector = observer(function CriteriaSelector({ criteria, value, state, className, style, children, delays }: CriteriaSelectorProps): React.ReactElement {
    const mainRef = useRef(null);
    const { t } = useTranslation("embed");
    const appStore = useAppStore();

    const handleClick = (event:React.MouseEvent<HTMLDivElement>) => {
        if (criteria) {
            event.stopPropagation();
            appStore.selectCriteria(criteria?.uuid);
        }
    };

    const isSelected = appStore.selectedCriteriaUuid === criteria?.uuid ? "selected" : "unselected";
    const classNames = criteria ? ["criteria", criteriaNameToUuid(criteria?.name)] : ["criteria"];
    const scaledSytyle = resolutionManager.scaleStyle(style);
    const subTitle = criteria && value !== -1 ? t(`criteria.${criteria.uuid}.value_${value}.title`):"-";
    return (
        <div ref={mainRef} className={className} style={scaledSytyle} onClick={handleClick}>
            <ToggleClip className={"ground"} style={{ left: 0, top: 0 }} state={isSelected}></ToggleClip>
            <div className="brick-wall"></div>
            <BrickGroup style={{ left: 132, top: -90 }} state={state} numBrick={value+1} animate={true} delays={delays}></BrickGroup>
            <ToggleClip className={classNames.join(" ")} style={{ left: 240, top: 60 }} state={state}></ToggleClip>
            <div className="criteria-desc">
                <div style={{ display: "flex", justifyContent: "end", alignItems: "flex-end" }}>
                    <h1 className="criteria-title prevent-select">{t(`criteria.${criteria?.uuid}.title`).toUpperCase()}</h1>
                    { subTitle === "-" ? <></>:<div className="arrow"></div> }
                </div>
                {  subTitle === "-" ? <></>:<h2 className="criteria-info prevent-select">{subTitle.toUpperCase()}</h2> }
                
            </div>
            {children}
        </div>
    );
});

export default CriteriaSelector;
import loglevel, {Logger, LogLevelDesc} from "loglevel";
import LogMessagePrefix from "loglevel-plugin-prefix";
const { REACT_APP_LOG_LEVEL } = process.env;

LogMessagePrefix.reg(loglevel);
LogMessagePrefix.apply(loglevel, {
    template: "[%t] %n.%l:",
    levelFormatter(level) {
        return level.toUpperCase();
    },
    nameFormatter(name) {
        return name || "global";
    },
    timestampFormatter(date) {
        return date.toISOString();
    }
});

const AppLogger: Logger = loglevel.getLogger("app");

AppLogger.setDefaultLevel("error");
if (REACT_APP_LOG_LEVEL) {
    AppLogger.setLevel(REACT_APP_LOG_LEVEL as LogLevelDesc, true);
    AppLogger.trace(`Logger level set to ${REACT_APP_LOG_LEVEL}`);
}

export { AppLogger };
import { AnnotationsMap, action, computed, makeObservable, observable, runInAction } from "mobx";
import SimpleClient from "./client/SimpleClient";
import Offer, { Criteria, CriteriaMeta, CriteriaTypes, MappingLink, PopupContent, Service, SolutionScore, notFoundOffer, notFoundPopup } from "./Offer";

import { SimpleNode, addParents } from "../tools/tree";
import { AppLogger } from "../tools/AppLogger";
import i18next, { initI18n } from "../app/initI18n";
import { Dictionary } from "../tools/types";


export interface PopupProps {
    type: string,
    uuid: string,
    offer?: Offer,
    zone?: string,
}

export enum PopupTypes {
    CRITERIA = "criteria",
    SERVICE = "service",
    OFFER = "offer",
}

export default class AppStore {

    public loaded = false;
    public offers:Record<string, Offer> = {};
    public offersList:string[] = [];
    public offersSortedList:string[] = [];
    public selectedCriteriaUuid:string|undefined;
    public selectedFocus:string|undefined;
    public serviceTree:SimpleNode; 
    public selectedOffer:Offer;    
    public mapping:Dictionary;
    public favortitePopups:PopupProps[] = [];

    protected _simpleClient = new SimpleClient();
    protected _offerSheetMap:Map<string, string> = new Map();
    protected _popup:Map<string, PopupContent>;
    // live-offer legacy
    protected _criteria: Map<string, Criteria> = new Map();
    protected _criteriaSheetMap:Map<string, string> = new Map();
    protected _criteriaValues: Map<string, number> = new Map();
    protected _criteriaMeta: Map<string, CriteriaMeta> = new Map();
    protected _solutionScores: Map<string, SolutionScore> = new Map();

    public popupList:PopupProps[] = [];
    public popupZIndex:number = 3000;
    protected _maxOpenPopups: number = 1;

    constructor() {

        this._popup = new Map();

        this.mapping = {};

        this.serviceTree = {uuid:"null"};

        this.selectedOffer = notFoundOffer();

        makeObservable(this, {
            loaded: observable,
            selectedOffer: observable,
            _solutionScores: observable,
            offersList: observable,
            offersSortedList: observable,
            selectedCriteriaUuid:observable,
            selectedFocus: observable,
            favortitePopups: observable,
            linefit:computed,
            _criteriaValues:observable,
            popupList: observable,
            selectTypeAircraft: action,
            restoreValuesFromLocalStorage: action,
            toggleFavorite: action,
            removeFromFavorite: action,
        } as AnnotationsMap<this, string>);

    }

    start = async ():Promise<void> => {

        await initI18n();
        i18next.changeLanguage('en-US');
        AppLogger.debug(`starting in ${i18next.language}`);

        await this.loadTranslations();

        this.mapping = await this._simpleClient.loadMapping();

        const offers = await this._simpleClient.loadOffers();
        // mapping because google sheet name are not uuid
        for (const key in offers) {
            const o = offers[key];
            o.linefit = o.linefit ?? false;
            this._offerSheetMap.set(o.spreed_sheet_name, key);
        }

        this.serviceTree = addParents(await this._simpleClient.loadServicesTree());

        this._criteriaMeta = await this._simpleClient.loadMetadata();
        // mapping because google sheet name are not uuid
        Array.from(this._criteriaMeta.values()).forEach(v => {
            this._criteriaSheetMap.set(v.name, v.uuid);
        });

        this._criteria = await this._simpleClient.loadCriteria(this._criteriaMeta);
        
        this.restoreValuesFromLocalStorage();

        this.offers = offers;
        const list = [];
        for (const key in this.offers) {
            list.push(key);
        }
        this.offersList = list;            

        this.selectedOffer = offers[this.getFilteredOfferList()[0]];

        this.calculateScores();

        this.setLoaded(true);
    };

    
    public getMapping(key:string):Dictionary|null {
        const list = key.split(".");
        let obj = this.mapping;
        for (const k of list) {
            if (!obj[k]) return null;
            obj = obj[k];
        }
        return obj;
    }

    public getMappingLink(key:string):MappingLink|null {
        const obj = this.getMapping(key);
        if (!obj) return null;
        return obj as MappingLink;
    }    

    public getMappingPopup(key:string, offer:Offer):string|null {
        const map = this.getMappingLink(key);
        if (!map) return null;
        if (map.offers.includes("*") || map.offers.includes(offer.uuid)) return map.popup;
        return null;
    }

    public get linefit():boolean {
        return this._criteriaValues.get(CriteriaTypes.aircraft_type) === 1;
    }

    public resetAll():void {
        this.resetCriterias();
        this.resetFavorites();
    }

    public resetCriterias():void {
        runInAction(() => {
            Array.from(this._criteria.keys()).forEach((e)=> {
                if (e !== CriteriaTypes.aircraft_type) {
                    this._criteriaValues.set(e, -1);
                }
            });          
        });
        this.saveCriteriaValues();
        this.calculateScores();
    }

    public resetFavorites():void {
        runInAction(() => {
            this.favortitePopups = [];
        });
        this.saveFavPopups();
    }


    public criteriaToDiplay():CriteriaTypes[] {
        return [CriteriaTypes.revenues,
            CriteriaTypes.internet_speed,
            CriteriaTypes.existing_solution,
            CriteriaTypes.ui_custo,
            CriteriaTypes.airline_control];
    }

    public selectTypeAircraft(linefit:boolean):void {
        const criteria = this.criteria.get(CriteriaTypes.aircraft_type);
        if (criteria) {
            this.changeCriteriaValue(criteria, linefit ? 1:0);
        }
        if (linefit) {
            if (this.selectedOffer.uuid === "pax") this.setSelectedOffer(this.offers["pax_linefit"]);
            if (this.selectedOffer.uuid === "display_360") this.setSelectedOffer(this.offers["display_360_linefit"]);
        }
        else {
            if (this.selectedOffer.uuid === "pax_linefit") this.setSelectedOffer(this.offers["pax"]);
            if (this.selectedOffer.uuid === "display_360_linefit") this.setSelectedOffer(this.offers["display_360"]);
        }
    }

    public selectCriteria(uuid:string):void {
        runInAction(()=>{
            this.selectedCriteriaUuid = uuid;            
        });
        this.addPopup({
            type: PopupTypes.CRITERIA,
            uuid: uuid
        });
    }

    setLoaded(loaded: boolean): void {
        runInAction(() => {
            this.loaded = loaded;
        });
    }    

    public getFilteredOfferList():string[] {
        const result:string[] = this.offersList.filter((v)=>{
            const offer = this.offers[v];
            if (offer) {
                return this.isOfferAvailable(offer);
            }
            return false;
        });        
        return result;
    }

    public getOrderOfferList():string[] {
        const offers = this.getFilteredOfferList();
        const list:string[] = [];
        this.getOrderedScores().map(s => {
            // const pct = (s.points*100/s.num_criteria);
            const uuid = this._offerSheetMap.get(s.name);
            if (uuid && offers.includes(uuid) && s.points !== 0) {                                                
                list.push(uuid);
            }
        });
        return list;
    }

    public getScoreForOffer(offer:Offer):SolutionScore|undefined {
        return this.scores.get(offer.uuid);
    }


    public isOfferAvailable(offer:Offer):boolean {
        return this.linefit ? offer.linefit:offer.retrofit;
    }

    public getOffersArray():Offer[] {
        return Object.keys(this.offers).map(k=>this.offers[k]);
    }
    
    public setSelectedOffer(offer:Offer):void {
        runInAction(()=>{
            this.selectedOffer = offer;
        });
    }

    public getOfferAt(index:number):Offer {
        return this.offers[this.offersList[index]];
    }

    public getOfferPosition(offer:Offer):number {
        return this.offersList.indexOf(offer.uuid);
    }

    public isServiceInOffer(offer:Offer, serviceUuid:string):boolean {
        return offer.services.full.includes(serviceUuid) || offer.services.medium.includes(serviceUuid);
    }

    public isServicesInOffer(offer:Offer, services:string[]):boolean {
        let present = false;
        for (const s of services) {
            present ||= this.isServiceInOffer(offer, s) ;
        }
        return present;
    }

    public async loadPopup(uuid:string):Promise<PopupContent> {

        const memory = this._popup.get(uuid);
        if (memory) {
            return memory;
        }
        const popup = await this._simpleClient.loadPopup(uuid);
        if (popup) {
            this._popup.set(uuid, popup);
            this.loadPagesI18n(popup.pages);
            if (popup.offers) {
                for (const k in popup.offers) {
                    const pages = popup.offers[k];
                    this.loadPagesI18n(pages);
                }
            }            
            return popup;
        }

        return notFoundPopup(uuid);
    }


    protected loadPagesI18n(pages:Service[]):void {
        pages.forEach((page) => {
            for (const key in page.locales) {
                const bundle = i18next.getResourceBundle(key, "embed");
                if (!bundle["pages"]) {
                    bundle["pages"] = {};                        
                }
                bundle["pages"][page.uuid] = page.locales[key];
                i18next.addResourceBundle(key, "embed", bundle);
            }    
        });
    }

    protected async loadTranslations():Promise<void> {
        const translations = await this._simpleClient.loadTranslations(["en"]);
        for (const key in translations) {
            const bundle = i18next.getResourceBundle(key, "embed");
            const merge = {...bundle, ...translations[key]};
            i18next.addResourceBundle(key, "embed", merge);
        }
    }

    public isPopupOpen(uuid:string):boolean {
        for (const p of this.popupList) {
            if (p.uuid === uuid) return true;
        }
        return false;
    }

    public addPopup(popup:PopupProps):void {
        if (this.isPopupOpen(popup.uuid)) {
            console.warn(`Allready open ${popup.uuid}`);
            return;
        }
        runInAction(()=> {
            const list = this.popupList;
            list.push(popup);
            this.popupList = [...list];
        });
    }

    public removePopup(uuid:string):void {
        if (!this.isPopupOpen(uuid)) {
            console.warn(`Not open ${uuid}`);
            return;
        }        
        runInAction(()=> {
            const list:PopupProps[] = [];
            for (const p of this.popupList) {
                if (p.uuid !== uuid) list.push(p);
            }
            this.popupList = [...list];
            if (!this.hasCriteriaPopup()) {
                this.selectedCriteriaUuid = "";
            }
    
        });             
    }

    protected hasCriteriaPopup():boolean {
        for (const p of this.popupList) {
            if (p.type === PopupTypes.CRITERIA) return true;
        }
        return false;
    }

    public closeAllPopups():void {
        runInAction(()=>{
            this.popupList = [];
            this.selectedCriteriaUuid = "";
            this.popupZIndex = 3000;
        });
    }

    public isFavorite(uuid:string):boolean {
        for (const popup of this.favortitePopups) {
            if (uuid === popup.uuid) return true;
        }
        return false;
    }
 
    public toggleFavorite(popup:PopupProps):void {
        console.log(`toggleFavorite ${popup.uuid}`);
        if (!this.isFavorite(popup.uuid)) {
            this.favortitePopups.push(popup);
            this.saveFavPopups();
        }
        else {
            this.removeFromFavorite(popup);
        }
    }

    public removeFromFavorite(popup:PopupProps):void {
        const index = this.favortitePopups.indexOf(popup);
        console.log(index);
        if (index !== -1) {
            this.favortitePopups.splice(index, 1);
            this.saveFavPopups();
        }
    }


    /************************************ */
    // live-offer legacy
    /************************************ */
    
    getCriteriaByUuid(uuid:string):Criteria|undefined {
        // const value = CriteriaTypes[key as keyof typeof CriteriaTypes];
        const key = uuid as keyof typeof CriteriaTypes;
        if (CriteriaTypes[key ]) {
            const name = CriteriaTypes[key];
            return this.criteria.get(name);
        }
        return;
    }    

    get criteriaMeta():Map<string, CriteriaMeta> {
        return this._criteriaMeta;
    }

    get scores():Map<string, SolutionScore> {
        return this._solutionScores;
    }

    get criteriaValues():Map<string, number> {
        return this._criteriaValues;
    }    

    get criteria():Map<string, Criteria> {
        return this._criteria;
    }

    public getOrderedScores():SolutionScore[] {
        let result = Array.from(this.scores.values());
        result = result.sort((a: SolutionScore, b:SolutionScore): number => {
            if (a.points === b.points) return a.weigth > b.weigth ? -1:1;
            return a.points > b.points ? -1:1;
        });
        return result;
    }

    public getPctComplete():number {
        const values = Array.from(this._criteriaValues.values());
        const numValues = values.length;
        let count = 0;
        values.forEach((v)=>{
            if (v !== -1) count++;
        });
        return count / numValues;
    }

    public calculateScores():void {

        if (this.getPctComplete() !== 1) return;

        runInAction(() => {
            this._solutionScores.clear();
            let totalPoints = 0;
            Array.from(this._criteriaValues.entries()).forEach(e=>{
                const criteria = this._criteria.get(e[0]);
                const meta = this._criteriaMeta.get(e[0]);
                if (criteria && meta) {
                    criteria.solutions.forEach((s)=> {                      
                        const offer_uuid = this._offerSheetMap.get(s.name);
                        if (offer_uuid) {
                            const offer = this.offers[offer_uuid];
                            let score = s.map.get(e[1]);

                            if (score === undefined) {
                                console.warn(`score missing for ${criteria.name} on solution ${s.name} ${offer_uuid}`);
                                score = 0;
                            }
                            if (!this._solutionScores.has(offer_uuid)) {
                                this._solutionScores.set(offer_uuid, {name: s.name, points: 0, details:[], pct:0, num_criteria:0, weigth:offer.weight});
                            }
                            const solutionScore = this._solutionScores.get(offer_uuid);
                            if (solutionScore) {
                                if (meta.multiplier) {
                                    solutionScore.points *= score;
                                } else {
                                    solutionScore.points += score * meta.coef;
                                    solutionScore.num_criteria += meta.coef;
                                }
                                totalPoints += score;
                                solutionScore.details.push({name: criteria.name, score:score, coef:meta.coef, multiplier:meta.multiplier});
                            }    
                        }
                    });
                }
            });
            // remove linefit / retrofit
            Array.from(this._solutionScores.keys()).forEach(k=>{
                const score = this._solutionScores.get(k);
                const offer = this.offers[k];
                if (score && offer) {
                    if (this.linefit && !offer.linefit) {
                        score.points = 0;
                    }
                    if (!this.linefit && !offer.retrofit) {
                        score.points = 0;
                    }
                }
            });

            // calculate pct
            Array.from(this._solutionScores.values()).forEach((s)=> {
                s.pct = s.points / totalPoints;
            });
        });
    }
    
    public debugCriteriaValues():void {
        Array.from(this._criteriaValues.keys()).forEach(k => {
            console.log(`${k} : ${this._criteriaValues.get(k)}`);
        });
    }

    public changeCriteriaValue(criteria:Criteria, value:number):void {
        runInAction(() => {
            this._criteriaValues.set(criteria.name, value);
            this.saveCriteriaValues();            
            this.calculateScores();   
            //this.debugCriteriaValues();        
        });
    }

    public saveCriteriaValues():void {
        localStorage.setItem('criteria_values', JSON.stringify(Array.from(this._criteriaValues.entries())));
    }

    protected loadCriteriaValues():Map<string, number> {
        const result = new Map();
        const values = localStorage.getItem('criteria_values');
        if (values) {
            try {
                const a = JSON.parse(values);
                a.forEach((e:[string, number]) => {
                    result.set(e[0], e[1]);
                });    
                return result;
            }
            catch (e) {
                AppLogger.error(e);
            }
        }
        Array.from(this._criteria.keys()).forEach((e)=> {            
            if (e === CriteriaTypes.aircraft_type) result.set(e, 0);
            else result.set(e, -1);
        }); 
        return result;      
    }


    protected loadFavPopups():PopupProps[] {
        const values = localStorage.getItem('fav_popups');
        if (values) {
            try {
                const popups = JSON.parse(values);
                return popups;
            }
            catch (e) {
                AppLogger.error(e);
            }
        }
        return [];
    }

    protected saveFavPopups():void {
        localStorage.setItem('fav_popups', JSON.stringify(this.favortitePopups));
    }

    public getCriteriaValue(criteria?:Criteria):number {
        if (!criteria) return 0;
        const value = this.criteriaValues.get(criteria.name);
        return value ?? 0;        
    }

    protected restoreValuesFromLocalStorage():void {
        this._criteriaValues = this.loadCriteriaValues();
        this.favortitePopups = this.loadFavPopups();
    } 
    
}


